import { Content } from "../../components/content/content";
import { Footer } from "../../components/footer/footer";
import { Nav } from "../../components/nav/nav";

import { Helmet } from "react-helmet";
import { Bkg } from "../../components/content/bkg";
import { t } from "../../utils/cms";

export function TermsPage() {
  return (
    <>
      <Helmet>
        <title>{t('terms.helmet.title')}</title>
        <meta
          name={t('terms.helmet.meta_name')}
          content={t('terms.helmet.meta_content')}
        />
      </Helmet>

      <Nav />

      <Content className="static">
        <h2>{t('terms.content.heading')}</h2>

        <div className="text-content">
          <h3>{t('terms.content.terms_of_service_heading')}</h3>
          <p>{t('terms.content.terms_of_service_paragraph')}</p>

          <h3>{t('terms.content.disclaimer_heading')}</h3>
          <p>{t('terms.content.disclaimer_paragraph1')}</p>
          <p>{t('terms.content.disclaimer_paragraph2')}</p>
          <p>{t('terms.content.disclaimer_paragraph3')}</p>

          <h4>{t('terms.content.acceptance_of_terms_heading')}</h4>
          <ul>
            <li>{t('terms.content.acceptance_of_terms_list1')}</li>
            <li>{t('terms.content.acceptance_of_terms_list2')}</li>
            <li>{t('terms.content.acceptance_of_terms_list3')}</li>
          </ul>

          <h4>{t('terms.content.provision_of_services_heading')}</h4>
          <p>{t('terms.content.provision_of_services_paragraph')}</p>

          <h4>{t('terms.content.eligibility_heading')}</h4>
          <p>{t('terms.content.eligibility_paragraph')}</p>

          <h4>{t('terms.content.content_heading')}</h4>
          <ul>
            <li>{t('terms.content.content_list1')}</li>
            <li>{t('terms.content.content_list2')}</li>
            <li>{t('terms.content.content_list3')}</li>
            <li>{t('terms.content.content_list4')}</li>
            <li>{t('terms.content.content_list5')}</li>
            <li>{t('terms.content.content_list6')}</li>
          </ul>

          <h4>{t('terms.content.rules_of_conduct_heading')}</h4>
          <ul>
            <li>{t('terms.content.rules_of_conduct_list1')}</li>
            <li>
              {t('terms.content.rules_of_conduct_list2')}
              <ul>
                <li>{t('terms.content.rules_of_conduct_nested_list1')}</li>
                <li>{t('terms.content.rules_of_conduct_nested_list2')}</li>
                <li>{t('terms.content.rules_of_conduct_nested_list3')}</li>
                <li>{t('terms.content.rules_of_conduct_nested_list4')}</li>
                <li>{t('terms.content.rules_of_conduct_nested_list5')}</li>
                <li>{t('terms.content.rules_of_conduct_nested_list6')}</li>
                <li>{t('terms.content.rules_of_conduct_nested_list7')}</li>
              </ul>
            </li>
            <li>{t('terms.content.rules_of_conduct_list3')}</li>
            <li>{t('terms.content.rules_of_conduct_list4')}</li>
            <li>{t('terms.content.rules_of_conduct_list5')}</li>
          </ul>

          <h4>{t('terms.content.registration_heading')}</h4>
          <ul>
            <li>{t('terms.content.registration_list1')}</li>
            <li>{t('terms.content.registration_list2')}</li>
            <li>{t('terms.content.registration_list3')}</li>
            <li>{t('terms.content.registration_list4')}</li>
            <li>{t('terms.content.registration_list5')}</li>
            <li>{t('terms.content.registration_list6')}</li>
          </ul>

          <h4>{t('terms.content.representations_heading')}</h4>
          <p>{t('terms.content.representations_paragraph')}</p>
          <ul>
            <li>{t('terms.content.representations_list1')}</li>
            <li>{t('terms.content.representations_list2')}</li>
            <li>{t('terms.content.representations_list3')}</li>
            <li>{t('terms.content.representations_list4')}</li>
            <li>{t('terms.content.representations_list5')}</li>
            <li>{t('terms.content.representations_list6')}</li>
            <li>{t('terms.content.representations_list7')}</li>
          </ul>

          <h4>{t('terms.content.third_party_services_heading')}</h4>
          <p>{t('terms.content.third_party_services_paragraph')}</p>

          <h4>{t('terms.content.payments_heading')}</h4>
          <ul>
            <li>{t('terms.content.payments_list1')}</li>
            <li>{t('terms.content.payments_list2')}</li>
            <li>{t('terms.content.payments_list3')}</li>
            <li>{t('terms.content.payments_list4')}</li>
            <li>{t('terms.content.payments_list5')}</li>
            <li>{t('terms.content.payments_list6')}</li>
            {/* <li>{t('terms.content.payments_list7')}</li> */}
          </ul>

          <h4>{t('terms.content.termination_heading')}</h4>
          <p>{t('terms.content.termination_paragraph')}</p>

          <h4>{t('terms.content.indemnification_heading')}</h4>
          <p>{t('terms.content.indemnification_paragraph')}</p>

          <h4>{t('terms.content.limitation_heading')}</h4>
          <p>{t('terms.content.limitation_paragraph1')}</p>
          <p>{t('terms.content.limitation_paragraph2')}</p>
          <ul>
            <li>{t('terms.content.limitation_nested_list1')}</li>
            <li>{t('terms.content.limitation_nested_list2')}</li>
            <li>{t('terms.content.limitation_nested_list3')}</li>
            <li>{t('terms.content.limitation_nested_list4')}</li>
            <li>{t('terms.content.limitation_nested_list5')}</li>
          </ul>
          <p>{t('terms.content.limitation_paragraph3')}</p>
          <p>{t('terms.content.limitation_paragraph4')}</p>

          <h4>{t('terms.content.governing_law_heading')}</h4>
          <p>{t('terms.content.governing_law_paragraph1')}</p>
          {/* <p>{t('terms.content.governing_law_paragraph2')}</p>
          <p>{t('terms.content.governing_law_paragraph3')}</p> */}

          <h4>{t('terms.content.modification_heading')}</h4>
          <p>{t('terms.content.modification_paragraph')}</p>

          <h4>{t('terms.content.feedback_heading')}</h4>
          <p>{t('terms.content.feedback_paragraph')}</p>

          <h4>{t('terms.content.email_heading')}</h4>
          <p>{t('terms.content.email_paragraph')}</p>

          <h4>{t('terms.content.miscellaneous_heading')}</h4>
          <ul>
            <li>{t('terms.content.miscellaneous_list1')}</li>
            <li>{t('terms.content.miscellaneous_list2')}</li>
            <li>{t('terms.content.miscellaneous_list3')}</li>
            <li>{t('terms.content.miscellaneous_list4')}</li>
            <li>{t('terms.content.miscellaneous_list5')}</li>
            <li>{t('terms.content.miscellaneous_list6')}</li>
            <li>{t('terms.content.miscellaneous_list7')}</li>
          </ul>

          <p>{t('terms.content.contact_info')}</p>
          <p><em>{t('terms.content.effective_date')}</em></p>
        </div>
      </Content>

      <Footer />
    </>

  );
}
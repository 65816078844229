import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

interface ScrollAnimationComponentProps {
  children: ReactNode; // Accept any kind of children
  animationName?: string;
  animationDelay?: string; // e.g., '2s', '100ms'
  animationDuration?: string; // e.g., '1s', '500ms'
  className?: string; // Custom class for the wrapping div
  style?: React.CSSProperties; // Custom style for the wrapping div
}

const ScrollAnimationComponent: React.FC<ScrollAnimationComponentProps> = ({
  children,
  animationName = 'fadeIn',
  animationDelay = '0s',
  animationDuration = '1s',
  className = '',
  style = {},
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing once visible
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const animationClass = isVisible ? `animate__${animationName}` : 'opacity-0';
  const initialStyle = {
    '--animate-delay': animationDelay,
    '--animate-duration': animationDuration,
    opacity: 0, // Initial opacity set to 0
    width: '100%',
    height: '100%',
    ...style,
  };

  const applyAnimationToChild = (child: ReactElement) =>
    React.cloneElement(child, {
      className: `${child.props.className ? child.props.className + ' ' : ''}animate__animated ${animationClass}`,
      style: {
        ...child.props.style,
        ...(!isVisible && initialStyle), // Apply initialStyle if not visible
      },
    });

  return (
    <div ref={ref} className={`${className} ${isVisible ? 'animate__animated ' + animationClass : ''}`} style={initialStyle}>
      {React.Children.map(children, child => 
        React.isValidElement(child) ? applyAnimationToChild(child) : child
      )}
    </div>
  );
};

export default ScrollAnimationComponent;

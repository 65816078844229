import { Content } from "../../components/content/content";
import { Footer } from "../../components/footer/footer";
import { Nav } from "../../components/nav/nav";

import { Helmet } from "react-helmet";
import { Bkg } from "../../components/content/bkg";
import {t} from "../../utils/cms";

export function PrivacyPage() {
  return (
    <>
  <Helmet>
    <title>{t('privacy.Page Metadata.helmet_title_privacy')}</title>
    <meta
      name="description"
      content={t('privacy.Page Metadata.helmet_meta_description_privacy')}
    />
  </Helmet>

  <Nav />

  <Bkg type="bottom" />

  <Content className="static">
    <h2>{t('privacy.Content Heading.content_heading_privacy')}</h2>
    <div className="text-content">
      <p>{t('privacy.Introduction.introduction_1_privacy')}</p>
      <p>{t('privacy.Introduction.introduction_2_privacy')}</p>
      <p>{t('privacy.Introduction.introduction_3_privacy')}</p>

      <h3>{t('privacy.Definitions.section_1_heading_privacy')}</h3>
      <p>{t('privacy.Definitions.section_1_intro_privacy')}</p>
      <p>{t('privacy.Definitions.section_1_terms_intro_privacy')}</p>

      <h4>{t('privacy.Definitions.definition_personal_data_privacy')}</h4>
      <p>{t('privacy.Definitions.definition_personal_data_description_privacy')}</p>

      <h4>{t('privacy.Definitions.definition_data_subject_privacy')}</h4>
      <p>{t('privacy.Definitions.definition_data_subject_description_privacy')}</p>

      <h4>{t('privacy.Definitions.definition_processing')}</h4>
      <p>{t('privacy.Definitions.definition_processing_description')}</p>

      <h4>{t('privacy.Definitions.definition_restriction_of_processing')}</h4>
      <p>{t('privacy.Definitions.definition_restriction_of_processing_description')}</p>

      <h4>{t('privacy.Definitions.definition_profiling')}</h4>
      <p>{t('privacy.Definitions.definition_profiling_description')}</p>

      <h4>{t('privacy.Definitions.definition_pseudonymization')}</h4>
      <p>{t('privacy.Definitions.definition_pseudonymization_description')}</p>

      <h4>{t('privacy.Definitions.definition_controller')}</h4>
      <p>{t('privacy.Definitions.definition_controller_description')}</p>

      <h4>{t('privacy.Definitions.definition_processor')}</h4>
      <p>{t('privacy.Definitions.definition_processor_description')}</p>

      <h4>{t('privacy.Definitions.definition_recipient')}</h4>
      <p>{t('privacy.Definitions.definition_recipient_description')}</p>

      <h4>{t('privacy.Definitions.definition_third_party')}</h4>
      <p>{t('privacy.Definitions.definition_third_party_description')}</p>

      <h4>{t('privacy.Definitions.definition_consent')}</h4>
      <p>{t('privacy.Definitions.definition_consent_description')}</p>

      <h3>{t('privacy.Privacy content 29.section_2_heading')}</h3>
      <p>{t('privacy.Privacy content 30.controller_information')}</p>
      <p>{t('privacy.Privacy content 31.controller_address')}</p>

      <h3>{t('privacy.Privacy content 32.section_3_heading')}</h3>
      <p>{t('privacy.Privacy content 33.cookies_intro')}</p>
      <p>{t('privacy.Privacy content 34.cookies_description_1')}</p>
      <p>{t('privacy.Privacy content 35.cookies_description_2')}</p>
      <p>{t('privacy.Privacy content 36.cookies_description_3')}</p>
      <p>{t('privacy.Privacy content 37.cookies_description_4')}</p>

      <h3>{t('privacy.Privacy content 38.section_4_heading')}</h3>
      <p>{t('privacy.Privacy content 39.general_data_collection')}</p>
      <p>{t('privacy.Privacy content 40.general_data_use')}</p>

      <h3>{t('privacy.Privacy content 41.section_5_heading')}</h3>
      <p>{t('privacy.Privacy content 42.registration_intro')}</p>
      <p>{t('privacy.Privacy content 43.registration_data_storage')}</p>
      <p>{t('privacy.Privacy content 44.registration_purpose')}</p>
      <p>{t('privacy.Privacy content 45.registration_rights')}</p>

      <h3>{t('privacy.Privacy content 46.section_6_heading')}</h3>
      <p>{t('privacy.Privacy content 47.newsletter_intro')}</p>
      <p>{t('privacy.Privacy content 48.newsletter_purpose')}</p>
      <p>{t('privacy.Privacy content 49.newsletter_data_storage')}</p>

      <h3>{t('privacy.Privacy content 50.section_7_heading')}</h3>
      <p>{t('privacy.Privacy content 51.newsletter_tracking_intro')}</p>
      <p>{t('privacy.Privacy content 52.newsletter_tracking_purpose')}</p>

      <h3>{t('privacy.Privacy content 53.section_8_heading')}</h3>
      <p>{t('privacy.Privacy content 54.contact_intro')}</p>

      <h3>{t('privacy.Privacy content 55.section_9_heading')}</h3>
      <p>{t('privacy.Privacy content 56.comments_intro')}</p>
      <p>{t('privacy.Privacy content 57.comments_data_storage')}</p>

      <h3>{t('privacy.Privacy content 58.section_10_heading')}</h3>
      <p>{t('privacy.Privacy content 59.erasure_intro')}</p>
      <p>{t('privacy.Privacy content 60.erasure_description')}</p>

      <h3>{t('privacy.Privacy content 61.section_11_heading')}</h3>
      <h4>{t('privacy.Privacy content 62.rights_confirmation')}</h4>
      <p>{t('privacy.Privacy content 63.rights_confirmation_description')}</p>

      <h4>{t('privacy.Privacy content 64.rights_access')}</h4>
      <p>{t('privacy.Privacy content 65.rights_access_description')}</p>
      <ul>
        <li>{t('privacy.Privacy content 66.rights_access_list_1')}</li>
        <li>{t('privacy.Privacy content 66.rights_access_list_2')}</li>
        <li>{t('privacy.Privacy content 66.rights_access_list_3')}</li>
        <li>{t('privacy.Privacy content 66.rights_access_list_4')}</li>
        <li>{t('privacy.Privacy content 66.rights_access_list_5')}</li>
        <li>{t('privacy.Privacy content 66.rights_access_list_6')}</li>
        <li>{t('privacy.Privacy content 66.rights_access_list_7')}</li>
        <li>{t('privacy.Privacy content 66.rights_access_list_8')}</li>
      </ul>
      <p>{t('privacy.Privacy content 67.rights_access_transfer')}</p>
      <p>{t('privacy.Privacy content 68.rights_access_contact')}</p>

      <h4>{t('privacy.Privacy content 69.rights_rectification')}</h4>
      <p>{t('privacy.Privacy content 70.rights_rectification_description')}</p>
      <p>{t('privacy.Privacy content 71.rights_rectification_contact')}</p>

      <h4>{t('privacy.Privacy content 72.rights_erasure')}</h4>
      <p>{t('privacy.Privacy content 73.rights_erasure_description')}</p>
      <ul>
        <li>{t('privacy.Privacy content 74.rights_erasure_list_1')}</li>
        <li>{t('privacy.Privacy content 74.rights_erasure_list_2')}</li>
        <li>{t('privacy.Privacy content 74.rights_erasure_list_3')}</li>
        <li>{t('privacy.Privacy content 74.rights_erasure_list_4')}</li>
        <li>{t('privacy.Privacy content 74.rights_erasure_list_5')}</li>
        <li>{t('privacy.Privacy content 74.rights_erasure_list_6')}</li>
      </ul>
      <p>{t('privacy.Privacy content 75.rights_erasure_contact')}</p>
      <p>{t('privacy.Privacy content 76.rights_erasure_public_data')}</p>

      <h4>{t('privacy.Privacy content 77.rights_restriction')}</h4>
      <p>{t('privacy.Privacy content 78.rights_restriction_description')}</p>
      <ul>
        <li>{t('privacy.Privacy content 79.rights_restriction_list_1')}</li>
        <li>{t('privacy.Privacy content 79.rights_restriction_list_2')}</li>
        <li>{t('privacy.Privacy content 79.rights_restriction_list_3')}</li>
        <li>{t('privacy.Privacy content 79.rights_restriction_list_4')}</li>
      </ul>
      <p>{t('privacy.Privacy content 80.rights_restriction_contact')}</p>

      <h4>{t('privacy.Privacy content 81.rights_portability')}</h4>
      <p>{t('privacy.Privacy content 82.rights_portability_description')}</p>
      <p>{t('privacy.Privacy content 83.rights_portability_transfer')}</p>
      <p>{t('privacy.Privacy content 84.rights_portability_contact')}</p>

      <h4>{t('privacy.Privacy content 85.rights_objection')}</h4>
      <p>{t('privacy.Privacy content 86.rights_objection_description')}</p>
      <p>{t('privacy.Privacy content 87.rights_objection_direct_marketing')}</p>
      <p>{t('privacy.Privacy content 88.rights_objection_contact')}</p>
      <p>{t('privacy.Privacy content 89.rights_objection_research')}</p>
      <p>{t('privacy.Privacy content 90.rights_objection_exercise')}</p>

      <h4>{t('privacy.Privacy content 91.rights_automated_decision_making')}</h4>
      <p>{t('privacy.Privacy content 92.rights_automated_decision_making_description')}</p>
      <p>{t('privacy.Privacy content 93.rights_automated_decision_making_contact')}</p>
      <p>{t('privacy.Privacy content 94.rights_automated_decision_making_exercise')}</p>

      <h4>{t('privacy.Privacy content 95.rights_withdraw_consent')}</h4>
      <p>{t('privacy.Privacy content 96.rights_withdraw_consent_description')}</p>
      <p>{t('privacy.Privacy content 97.rights_withdraw_consent_contact')}</p>

      <h3>{t('privacy.Privacy content 98.section_12_heading')}</h3>
      <p>{t('privacy.Privacy content 99.legal_basis_intro')}</p>
      <p>{t('privacy.Privacy content 100.legal_basis_vital_interests')}</p>
      <p>{t('privacy.Privacy content 101.legal_basis_legitimate_interests')}</p>

      <h3>{t('privacy.Privacy content 102.section_13_heading')}</h3>
      <p>{t('privacy.Privacy content 103.legitimate_interests')}</p>

      <h3>{t('privacy.Privacy content 104.section_14_heading')}</h3>
      <p>{t('privacy.Privacy content 105.storage_period')}</p>

      <h3>{t('privacy.Privacy content 106.section_15_heading')}</h3>
      <p>{t('privacy.Privacy content 107.data_provision_intro')}</p>

      <h3>{t('privacy.Privacy content 108.section_16_heading')}</h3>
      <p>{t('privacy.Privacy content 109.automated_decision_making_intro')}</p>

      <h3>{t('privacy.Privacy content 110.section_17_heading')}</h3>
      <p>{t('privacy.Privacy content 111.google_analytics_intro')}</p>
      <p>{t('privacy.Privacy content 112.google_analytics_operator')}</p>
      <p>{t('privacy.Privacy content 113.google_analytics_anonymization')}</p>
      <p>{t('privacy.Privacy content 114.google_analytics_purpose')}</p>
      <p>{t('privacy.Privacy content 115.google_analytics_cookie')}</p>
      <p>{t('privacy.Privacy content 116.google_analytics_data_storage')}</p>
      <p>{t('privacy.Privacy content 117.google_analytics_prevention')}</p>
      <p>{t('privacy.Privacy content 118.google_analytics_objection')}</p>
      <p>{t('privacy.Privacy content 119.google_analytics_info')}</p>

      <h3>{t('privacy.Privacy content 120.section_18_heading')}</h3>
      <p>{t('privacy.Privacy content 121.sumsub_intro')}</p>
      <p>{t('privacy.Privacy content 122.sumsub_operator')}</p>
      <p>{t('privacy.Privacy content 123.sumsub_purpose')}</p>
      <p>{t('privacy.Privacy content 124.sumsub_cookie')}</p>
      <p>{t('privacy.Privacy content 125.sumsub_prevention')}</p>
    </div>
  </Content>

  <Footer />
</>

  );
}
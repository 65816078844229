import { gql } from "@apollo/client";

export const MUTATION_ADD_INVESTOR_LEAD = gql`
  mutation registerInterest(
    $email: String!,
    $fullName: String!,
    $phoneNumber: String!,
    $utm_url: String!,
    $utm_campaign: String,
    $utm_medium: String,
    $utm_source: String,
    $utm_content: String,
    $utm_term: String,
    $utm_id: String,
    $preferredPropertyId: String,
    $expectInvestmentAmount: Int
  ) {
    registerInterest(
      data: {
        email: $email,
        fullName: $fullName,
        phoneNumber: $phoneNumber,
        utm_url: $utm_url,
        utm_campaign: $utm_campaign,
        utm_medium: $utm_medium,
        utm_source: $utm_source,
        utm_content: $utm_content,
        utm_term: $utm_term,
        utm_id: $utm_id,
        preferredPropertyId: $preferredPropertyId,
        expectInvestmentAmount: $expectInvestmentAmount
      }
    )
  }
`;
import { Link } from 'react-router-dom';
import { AppRoute } from '../../routes';
import ImgLogo from './../../assets/img/common/logo_white.svg';

export function Footer() {
  return (
    <footer>
      <div className='footer-content'>
        <div className="logo">
          <img src={ImgLogo} alt="PropNerd" height={40} />
          {/* <h4><b>Fast, Easy, Secure</b></h4> */}
          <img src={ImgLogo} alt="PropNerd" height={40} style={{ visibility: 'hidden'}} />
        </div>
        <div className='rows'>
          <div className='links'>
            <h3>PropNerd</h3>
            <Link to={AppRoute.About}>About</Link>
            <Link to={AppRoute.PropertyMarketplace}>Properties</Link>
            <Link to={AppRoute.Services}>Services</Link>
            <Link to={AppRoute.Faq}>FAQs</Link>
            <Link to={AppRoute.CustomerSupport}>Customer Support</Link>
          </div>
          <div className="address">
            <h3>Office Address</h3>
            <p>23 Berkeley Square</p>
            <p>Mayfair, London</p>
            <p>United Kingdom, W1J6HE</p>
          </div>
          <div className="contact">
            <h3>Contact</h3>
            <p>+44(0)203 307 3187</p>
            <p>info@propnerd.io</p>
            <p>M-F: 09.00 - 17.00</p>
          </div>
        </div>
        <div className='info'>
          <a>&copy; 2024 PropNerd. All rights reserved.</a>
          <Link to={AppRoute.Disclaimer}>Disclaimer</Link>
          <Link to={AppRoute.Terms}>Terms of Service</Link>
          <Link to={AppRoute.Privacy}>Privacy Policy</Link>
          <Link to={AppRoute.Privacy}>Cookie Policy</Link>
        </div>
        <div className='disclaimer'>
        This site is operated by PropNerd, which is not a registered broker-dealer or investment advisor. PropNerd does not provide investment advice, endorsements, or recommendations with respect to any of the properties listed on this site. Nothing on this website should be construed as an offer to sell, solicitation of an offer to buy, or a recommendation in respect of a security. You are solely responsible for deciding whether any investment, investment strategy or related transaction is appropriate for you based on your personal investment objectives, financial circumstances, and risk tolerance. Remember, the value of any investment can go up or down. You should consult with licensed legal professionals and investment advisors for any legal, tax, insurance, or investment advice before making an investment decision. PropNerd does not guarantee any investment performance, outcome or return of capital for any investment opportunity posted on this site. By accessing this site and any pages thereof, you agree to be bound by our Terms of Service and Privacy Policy.
        </div>
      </div>
    </footer>
  )
}
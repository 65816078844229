import { Content } from "../../components/content/content";
import { Footer } from "../../components/footer/footer";
import { Nav } from "../../components/nav/nav";

import { Helmet } from "react-helmet";
import { ReactComponent as ImgTokenisation } from '../../assets/img/services/tokenization.svg';
import { ReactComponent as ImgPlatform } from '../../assets/img/services/platform.svg';
import { ReactComponent as ImgPortfolio } from '../../assets/img/services/portfolio.svg';
import { ReactComponent as ImgCompliance } from '../../assets/img/services/compliance.svg';
import { Bkg } from "../../components/content/bkg";
import {t} from '../../utils/cms'

export function ServicesPage() {
  return (
    <>
      <Helmet>
        <title>{t('services.meta information.meta_title_services')}</title>
        <meta name="description" content={t('services.meta information.meta_description_services')} />
      </Helmet>

      <Nav />

      <Bkg type="bottom" />

      <Content className="services">
        <h2>{t('services.introduction.title_services')}</h2>
        <div className="section-container m-flex-column">
          <div className="section">
            <ImgTokenisation />
            <div className="text">
              <h3>{t('services.property tokenisation.heading_property_tokenisation')}</h3>
              <p>{t('services.property tokenisation.content_property_tokenisation')}</p>
            </div>
          </div>
          <div className="section">
            <ImgPlatform />
            <div className="text">
              <h3>{t('services.investment platform.heading_investment_platform')}</h3>
              <p>{t('services.investment platform.content_investment_platform')}</p>
            </div>
          </div>
          <div className="section">
            <ImgPortfolio />
            <div className="text">
              <h3>{t('services.portfolio management.heading_portfolio_management')}</h3>
              <p>{t('services.portfolio management.content_portfolio_management')}</p>
            </div>
          </div>
          <div className="section">
            <ImgCompliance />
            <div className="text">
              <h3>{t('services.regulatory compliance.heading_regulatory_compliance')}</h3>
              <p>{t('services.regulatory compliance.content_regulatory_compliance')}</p>
            </div>
          </div>
        </div>
      </Content>

      <Footer />
    </>

  );
}
export function getPropertyImageUrl(propertyId: string, image: { id: string, ext: string }, width?: number, height?: number) {
  const key = `property/${propertyId}/images/${image.id}.${image.ext}`;
  const encodedOptions = btoa(JSON.stringify({
    key,
    bucket: process.env.REACT_APP_CDN_BUCKET_NAME,
    edits: { resize: { height, width, fit: 'cover' } },
    toFormat: 'png',
  }))
  return `${process.env.REACT_APP_CDN_URL}/${encodedOptions}`;
}

import { useEffect, useState } from "react";
import ScrollAnimationComponent from "../../../components/animation/scroll-animation.component";
import { Slider } from "../component/slider.component";
import { CalculatorChart } from "../component/calculator-chart.component";
import { t } from "../../../utils/cms";


export function HomeCalculatorSection() {

  const [calculatorInvestment, setCalculatorInvestment] = useState(25);
  const [calculatorGrowth, setCalculatorGrowth] = useState(30);
  const [calculatorYield, setCalculatorYield] = useState(66.67);
  const [calculatedInvestment, setCalculatedInvestment] = useState(0);
  const [calculatedValueAppreciation, setCalculatedValueAppreciation] = useState(0);
  const [calculatedAnnualIncome, setCalculatedAnnualIncome] = useState(0);

  useEffect(() => {
    function updateCalculatedValues() {
      const investment = investmentValue(calculatorInvestment);
      const growth = investment * (calculatorGrowth / 100 * 5);
      const _yield = investment * (calculatorYield / (100 / 15) / 100);

      setCalculatedInvestment(investment);
      setCalculatedValueAppreciation(growth);
      setCalculatedAnnualIncome(_yield);
    }

    updateCalculatedValues();
  }, [calculatorInvestment, calculatorGrowth, calculatorYield])

  function investmentValueFn(percentage: number): string {
    const value = investmentValue(percentage);

    // Format the value to be in 'k's and return
    const formattedValue = `£${(value / 1000).toFixed(0)}k`;

    return formattedValue;
  }

  function investmentValue(percentage: number): number {
    const minValue = 0; // Corresponds to 0%
    const maxValue = 200000; // Corresponds to 100%
    const minStepValue = 1000; // Minimum stepping value

    // Applying a power function to the percentage
    const scaledPercentage = Math.pow(percentage / 100, 2); // Square the percentage to increase scaling
    
    // Calculating the scaled value
    let value = minValue + (maxValue - minValue) * scaledPercentage * 4;

    // Ensuring minimum stepping value
    return Math.ceil(value / minStepValue) * minStepValue;
  }

  function yieldValueFn(percentage: number): string {
    const minValue = 0; // Corresponds to 0%
    const maxValue = 15; // Corresponds to 100%
    const value = minValue + (maxValue - minValue) * (percentage / 100);

    // Format the value to be in 'k's and return
    const formattedValue = `${value.toFixed(0)}%`;

    return formattedValue;
  }

  function formatCurrency(value: number): string {
    return `£${value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }

  function generateSnapPoints(min: number, max: number, step: number): number[] {
    const snapPoints = [];
    for (let i = min; i <= max; i += step) {
      snapPoints.push(i * 100 / max);
    }
    return snapPoints;
  }

  return (
    <div className="calculator">
      <h2>{t('homeCalculator.Calculator Overview.calculator_heading_home')}</h2>
      <p>{t('homeCalculator.Calculator Overview.calculator_description_home')}</p>
      <div className="calculator-content">
        <ScrollAnimationComponent className="calculator-text" animationName="fadeInRightBig">
          <CalculatorChart investment={calculatedInvestment} valueAppreciation={calculatedValueAppreciation} annualIncome={calculatedAnnualIncome} />
          {/* <h2>UK investment calculator</h2>
          <p>Use the calculator to find out what your investment returns will be over a 5 year period.</p>
          <p>All projected values are before any property costs and platform fees, and based on a 5-year holding period.</p> */}
        </ScrollAnimationComponent>
        <ScrollAnimationComponent className="calculator" animationName="fadeInLeftBig">
          <h3>{t('homeCalculator.Investment Inputs.investment_query_home')}</h3>
          <Slider
            title={t('homeCalculator.Investment Inputs.initial_investment_home')}
            valueFn={investmentValueFn} defaultValue={calculatorInvestment}
            snapPoints={generateSnapPoints(0, 800000, 10000)}
            onChange={setCalculatorInvestment}
          />
          <Slider
            title={t('homeCalculator.Investment Projections.property_value_growth_home')}
            defaultValue={calculatorGrowth}
            snapPoints={generateSnapPoints(0, 100, 1)}
            onChange={setCalculatorGrowth}
          />
          <Slider
            title={t('homeCalculator.Investment Projections.expected_annual_rental_yield_home')}
            valueFn={yieldValueFn} defaultValue={calculatorYield}
            snapPoints={generateSnapPoints(0, 15, 1)}
            onChange={setCalculatorYield}
          />
          <table className="calculator-summary">
            <tbody>
              <tr>
                <td className="calculator-summary-header">{t('homeCalculator.Investment Results.value_appreciation_home')}</td>
                <td className="calculator-summary-value">{formatCurrency(calculatedValueAppreciation)}</td>
              </tr>
              <tr>
                <td className="calculator-summary-header">{t('homeCalculator.Investment Results.expected_annual_income_home')}</td>
                <td className="calculator-summary-value calculator-summary-value-total">{formatCurrency(calculatedAnnualIncome)}</td>
              </tr>
            </tbody>
          </table>
        </ScrollAnimationComponent>
      </div>
    </div>
  );
}
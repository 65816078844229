import { Content } from "../../components/content/content";
import { Footer } from "../../components/footer/footer";
import { Nav } from "../../components/nav/nav";

import { Helmet } from "react-helmet";
import ImgHero1 from '../../assets/img/about/hero_1.png';
import ImgHero2 from '../../assets/img/about/hero_2.png';
import ImgHero3 from '../../assets/img/about/hero_3.png';
import { Bkg } from "../../components/content/bkg";

import ImgPropNerd from '../../assets/img/common/favicon.png';
import { ReactComponent as ImgMission } from '../../assets/img/about/mission.svg';
import { ReactComponent as ImgInvestor } from '../../assets/img/about/investor.svg';
import { t } from "../../utils/cms";
export function HowItWorksPage() {
  return (
    <>
      <Helmet>
        <title>{t('how.Page Metadata.helmet_title_how')}</title>
        <meta name="description" content={t('how.Page Metadata.helmet_meta_description_how')} />
      </Helmet>

      <Nav />

      <Bkg />

      <Content className="how">
        <div className="subheader">
          <h3>{t('how.Subheader.subheader_heading_how')}</h3>
          <h4>{t('how.Subheader.subheader_subheading_how')}</h4>
        </div>

        <div className="vismis flex m-flex-column m-align-center gap-lg mb-5 md:p-5 mt-5">
          <img src={ImgPropNerd} alt="PropNerd" width={100} height={100} />
          <div>
            <h4>{t('how.Introduction.section_1_heading_how')}</h4>
            <p>{t('how.Introduction.section_1_description_how')}</p>
          </div>
        </div>

        <div className="flex m-flex-column gap-lg">
          <div className="vismis flex-1 flex flex-column m-align-center gap-lg mb-5 md:p-5">
            <div>
              <h4>{t('how.How It Works.section_2_heading_how')}</h4>
              <p>{t('how.How It Works.section_2_description_how')}</p>
            </div>
            <div>
              <h4>{t('how.User Benefits.section_3_heading_how')}</h4>
              <p>{t('how.User Benefits.section_3_description_how')}</p>
            </div>
            <div>
              <h4>{t('how.Trust and Security.section_4_heading_how')}</h4>
              <p>{t('how.Trust and Security.section_4_description_how')}</p>
            </div>
          </div>

          <div className="advantages flex-1">
            <div className="flex gap-lg m-flex-column">
              <div>
                <h4>{t('how.Ownership Benefits.section_5_heading_how')}</h4>
                <div className="card-content">
                  <div>
                    <h5>{t('how.Ownership Benefits.true_ownership_title')}</h5>
                    <p>{t('how.Ownership Benefits.true_ownership_description')}</p>
                  </div>
                  <div>
                    <h5>{t('how.Ownership Benefits.simplicity_convenience_title')}</h5>
                    <p>{t('how.Ownership Benefits.simplicity_convenience_description')}</p>
                  </div>
                  <div>
                    <h5>{t('how.Ownership Benefits.tax_advantages_title')}</h5>
                    <p>{t('how.Ownership Benefits.tax_advantages_description')}</p>
                  </div>
                  <div>
                    <h5>{t('how.Ownership Benefits.accessibility_for_all_title')}</h5>
                    <p>{t('how.Ownership Benefits.accessibility_for_all_description')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>

      <Footer />
    </>
  );
}
import React from "react";
import PdfIcon from "../../assets/svgs/pdf.svg"; // Replace with actual path to your PDF icon
import DownloadIcon from "../../assets/svgs/download.svg"; // Replace with actual path to your download icon

interface Document {
  id: string;
  filename: string;
  url: string;
}

interface DocumentsProps {
  documents: Document[];
  propertyId: string;
}

const Documents: React.FC<DocumentsProps> = ({ documents, propertyId }) => {
  if (!documents || documents.length === 0) {
    return null;
  }
  return (
    <div className="documents-container">
      <h3 className="documents-title">Property Documents</h3>
      <ul className="documents-list">
        {documents.map((document) => (
          <li key={document.id} className="document-item">
            <a
              href={document.url}
              rel="noreferrer"
              target="_blank"
              className="document-link"
            >
              <div className="document-gradient" />
              <div className="document-item-container">
              {/* <img src={PdfIcon} alt="PDF" className="document-icon" />{" "} */}
                <p className="document-name">{document.filename}</p>
              <img src={DownloadIcon} alt="Download" className="download-icon" />
              </div>
              <div className="document-info">
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Documents;

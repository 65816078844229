import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const PropertySkeleton = () => {
  return (
    <Box className="property">
      {/* Header Skeleton */}
      <div className="">
        <Skeleton variant="text" width="100%" height={160}  />
        
      </div>

      {/* Image and Overview Section */}
      <div className="section-container flex flex-wrap gap-lg mt-4">
        <div className="small-images hidden-mobile-skeleton" style={{ width: '18%' }} >
          <Skeleton variant="rectangular" height={480} className="mb-3"style={{ borderRadius : "10px" }} />
        </div>
        <Skeleton variant="rectangular" height={480} width="60%" className="mb-3 big-image-skeleton" style={{ borderRadius : "10px" }} />
        
        <div className="detail-cards-skeleton flex flex-column gap-sm" style={{ width: '18%' }}>
          <Skeleton variant="rectangular" height={235} style={{ borderRadius : "10px" }} />
          <Skeleton variant="rectangular" height={235} style={{ borderRadius : "10px" }}/>
        </div>
      </div>

      {/* Tab Section Skeleton */}
      <div className="mt-5">
        <Skeleton variant="text" width="30%" height={50} className="mb-3" style={{ borderRadius : "10px" }}/>
        <Skeleton variant="rectangular" width="100%" height={300} className="mb-4" style={{ borderRadius : "10px" }} />
      </div>

      {/* Map Section Skeleton */}
      <h2 className="documents-title mt-5">
        <Skeleton variant="text" width="30%" height={40} style={{ borderRadius : "10px" }}/>
      </h2>
      <div className="map">
        <Skeleton variant="rectangular" width="100%" height={300}  style={{ borderRadius : "10px" }}/>
      </div>
    </Box>
  );
};

export default PropertySkeleton;

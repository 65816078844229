import Cms_values  from '../assets/cms-data.json';
import i18n from "../i18n";

export function t(key: string) {
    if (!key) {
      return key;
    }
    const [pageKey, sectionKey, valueKey] = key.split('.');
  
    if (!pageKey || !sectionKey || !valueKey) {
      return key;
    }
  
    const detectedLanguage = i18n.language.split('-')[0]; // or this.lang
    const valueObj: any = Cms_values.values.find((v: any) => v.page === pageKey && v.section === sectionKey && v.key === valueKey && v.language === detectedLanguage);
    return valueObj ? valueObj.value : key;
  }
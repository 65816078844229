import { useLazyQuery } from "@apollo/client";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { Helmet } from "react-helmet";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useParams } from "react-router-dom";
import ImgMarker from "../../../assets/img/common/favicon.png";
import LeftArrow from "../../../assets/svgs/arrow-left.svg";
import RightArrow from "../../../assets/svgs/arrow-right.svg";
import BathIcon from "../../../assets/svgs/bath.svg";
import BedIcon from "../../../assets/svgs/bed.svg";
import Ellipse from "../../../assets/svgs/ellipse.svg";
import Ellipse1 from "../../../assets/svgs/ellipse-1.svg";
import Ellipse2 from "../../../assets/svgs/ellipse-2.svg";
import Ellipse3 from "../../../assets/svgs/ellipse-3.svg";
import RoiArrowUpIcon from "../../../assets/svgs/roi-arrow.svg";
import MapPointer from "../../../assets/svgs/map.svg";
import { Content } from "../../../components/content/content";
import { Footer } from "../../../components/footer/footer";
import { Nav } from "../../../components/nav/nav";
import SmallImages from "../../../components/property/small-images";
import TabComponent from "../../../components/property/tab-component";
import PropertySkeleton from "../../../components/skeleton/propertyskeleton";
import { QUERY_GET_PROPERTY } from "../../../graphql/queries/property";
import { toCurrencyDisplay, toTokens } from "../../../utils/currency.util";
import { getPropertyDocumentUrl } from "../../../utils/document.util";
import { getPropertyImageUrl } from "../../../utils/image.utils";
import { toPercentageDisplay } from "../../../utils/string.util";
import { CalculatorChart } from "../../home/component/calculator-chart.component";
import {
  PropertyPledge,
  PropertyPledgeStatus,
} from "../../../__generated__/graphql";

const libraries = ["places", "marker"];

export function PropertyDetailsPage() {
  const [images, setImages] = useState<
    { id: string; ext: string; order: number }[]
  >([]);
  const [loadingImages, setLoadingImages] = useState(
    Array(images.length).fill(true)
  );
  const [getProperty, { data, loading }] = useLazyQuery<{
    publicProperty: any;
  }>(QUERY_GET_PROPERTY, { errorPolicy: "all" });
  const { id } = useParams();

  const { isLoaded: isGoogleApiLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
    libraries: libraries as any,
  });
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [marker, setMarker] =
    useState<google.maps.marker.AdvancedMarkerElement>();

  const map = useRef<google.maps.Map | null>(null);
  const [selectedImage, setSelectedImage] = useState(
    data?.publicProperty?.images[0]
  );
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageSelect = (image: any) => {
    setSelectedImage(image);
  };
  const [transitioning, setTransitioning] = useState(false);
  const smallImagesRef = useRef<HTMLDivElement>(null);

  const handleImageTransition = (direction: "next" | "prev") => {
    if (transitioning) return;

    setTransitioning(true);
    const newIndex =
      direction === "next"
        ? (currentImageIndex + 1) % images.length
        : (currentImageIndex - 1 + images.length) % images.length;

    // setSelectedImage(null); // This will trigger the fade-out

    setTimeout(() => {
      setCurrentImageIndex(newIndex);
      setSelectedImage(images[newIndex]);

      // Scroll the thumbnail into view
      if (smallImagesRef.current) {
        const thumbnails =
          smallImagesRef.current.getElementsByClassName("small-image");
        if (thumbnails[newIndex]) {
          thumbnails[newIndex].scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }
      }

      setTimeout(() => setTransitioning(false), 500); // Match this with your CSS transition time
    }, 250); // Half of the fade-out time
  };

  const handleNextImage = () => handleImageTransition("next");
  const handlePrevImage = () => handleImageTransition("prev");

  useEffect(() => {
    setSelectedImage(images[currentImageIndex]);
  }, [currentImageIndex, images]);

  useEffect(() => {
    getProperty({ variables: { id } });
  }, []);
  useEffect(() => {
    if (data && data.publicProperty && mapLoaded) {
      applyMarker();
    }

    if (data && data.publicProperty) {
      setImages(sortImages(data.publicProperty.images));
      setLoadingImages(Array(data.publicProperty.images.length).fill(true));
    }
  }, [data, mapLoaded]);

  function applyMarker() {
    if (
      data &&
      data.publicProperty &&
      data.publicProperty.location &&
      data.publicProperty.location.coordinates
    ) {
      setMarker(
        new google.maps.marker.AdvancedMarkerElement({
          position: new google.maps.LatLng(
            data.publicProperty.location.coordinates[1],
            data.publicProperty.location.coordinates[0]
          ),
          map: map.current,
          content: createMarkerContent(data.publicProperty),
        })
      );
    }
  }

  function onMapLoad(_map: google.maps.Map) {
    setMapLoaded(true);
    map.current = _map;

    if (
      mapLoaded &&
      data &&
      data.publicProperty &&
      data.publicProperty.location &&
      data.publicProperty.location.coordinates
    ) {
      applyMarker();
    }
  }

  const createMarkerContent = (property: any) => {
    const markerContent = document.createElement("div");
    const root = createRoot(markerContent);
    root.render(<MarkerContent {...property} />);
    return markerContent;
  };

  const fundedPercentage = (property: any) => {
    const confirmedPledges = property.pledges.filter(
      (pledge: any) => pledge.status === PropertyPledgeStatus.Confirmed
    );
    const totalConfirmedAmount = confirmedPledges.reduce(
      (sum: any, pledge: any) => sum + pledge.amount,
      0
    );
    return ((totalConfirmedAmount / property.price) * 100).toFixed(2);
  };

  const financialData = {
    propertyPrice: toCurrencyDisplay(
      data?.publicProperty.price || data?.publicProperty.agreementPrice
    ),
    transactionCosts: toCurrencyDisplay(data?.publicProperty.transactionFee),
    platformFee: toCurrencyDisplay(data?.publicProperty.platformFee),
    investmentCost: toCurrencyDisplay(
      (data?.publicProperty.price || data?.publicProperty.agreementPrice) +
        data?.publicProperty.transactionFee +
        data?.publicProperty.platformFee
    ),
    annualGrossRent: toCurrencyDisplay(
      data?.publicProperty.financials[0].annualRent
    ),
    serviceCharges: toCurrencyDisplay(
      data?.publicProperty.financials[0].annualServiceCharges
    ),
    maintainCharges: toCurrencyDisplay(
      data?.publicProperty.financials[0].annualManagementAndMaintenanceCharges
    ),
    annualNetIncome: toCurrencyDisplay(
      parseInt(data?.publicProperty.financials[0].annualRent) -
        parseInt(data?.publicProperty.financials[0].annualServiceCharges || 0) -
        parseInt(data?.publicProperty.financials[0].annualManagementAndMaintenanceCharges || 0)
    ),
  };

  const documents =
    data?.publicProperty.documents.length > 0
      ? data?.publicProperty.documents.map((doc: any) => ({
          id: doc.id,
          filename: doc.filename,
          url: getPropertyDocumentUrl(data.publicProperty.id, doc),
        }))
      : null;

  function sortImages(images: { id: string; ext: string; order: number }[]) {
    return images.slice().sort((a, b) => a.order - b.order);
  }

  const handleImageLoad = (index: number) => {
    const updatedLoadingImages = [...loadingImages];
    updatedLoadingImages[index] = false;
    setLoadingImages(updatedLoadingImages);
  };

  return (
    <>
      <Helmet>
        <title>{`PropNerd ${
          data && data.publicProperty ? " | " + data.publicProperty.title : ""
        }`}</title>
        <meta
          name="description"
          content={
            data && data.publicProperty && data.publicProperty.description
          }
        />
      </Helmet>

      <Nav />

      {loading || !isGoogleApiLoaded ? (
        <PropertySkeleton />
      ) : data && data.publicProperty ? (
        
        <Content className="property-details">
          <div className="header">
            <div className="header-content">
              <h2 className="property-heading">{data.publicProperty.title}</h2>
              <div className="property-progress-wrapper">
                <div className="progress-text-container">
                  <span className="funded-tag">Funded</span>
                  <div className="property-progress-text">
                    {fundedPercentage(data.publicProperty)}%
                  </div>
                </div>
                <div className="progress-container">
                  <div className="property-progress-container">
                    <div
                      className="property-progress-bar"
                      style={{
                        width: `${fundedPercentage(data.publicProperty)}%`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {images && !!images.length && (
            <div className="section-container">
              {
                <SmallImages
                  images={images}
                  propertyId={data.publicProperty.id}
                  onImageSelect={handleImageSelect}
                  selectedImage={selectedImage}
                  containerRef={smallImagesRef}
                />
              }

              <div className="big-image-container">
                <div className="tokens-indicator">
                  <img src={Ellipse} alt="Ellipse" />
                  <span>
                    Tokens:{" "}
                    {toTokens(
                      data.publicProperty.price ||
                        data.publicProperty.agreementPrice
                    )}
                  </span>
                </div>

                <Zoom>
                  {loadingImages[currentImageIndex] && (
                    <div className="pulse-loader"></div>
                  )}
                  <img
                    className={`big-image ${
                      selectedImage ? "fade-in" : "fade-out"
                    }`}
                    src={getPropertyImageUrl(
                      data.publicProperty.id,
                      selectedImage ? selectedImage : images[0],
                      undefined,
                      605
                    )}
                    onLoad={() => handleImageLoad(currentImageIndex)}
                    alt={data.publicProperty.title}
                  />
                </Zoom>
                <div className="gradient-overlay"></div>

                <div className="image-details">
                  <div className="property-info-wrapper">
                    <div className="property-information">
                      <h1 className="property-title">
                        {data.publicProperty.title}
                      </h1>

                      <p className="property-info">
                        <img src={BedIcon} alt="Bed Icon" className="icon" />{" "}
                        <span className="sub-info">
                          {data.publicProperty.bed} |{"  "}
                        </span>
                        <img src={BathIcon} alt="Bath Icon" className="icon" />{" "}
                        <span className="sub-info">
                          {data.publicProperty.bath} |{"  "}
                        </span>
                        <span className="sub-info">
                          {data.publicProperty.sqFootage} sq.ft{" "}
                        </span>
                      </p>
                    </div>
                    <p className="property-price">
                      Price
                      <br />
                      <span className="property-price-value">
                        {toCurrencyDisplay(
                          data.publicProperty.price ||
                            data.publicProperty.agreementPrice
                        )}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="image-navigation">
                  <img
                    className="nav-arrow"
                    onClick={handlePrevImage}
                    src={LeftArrow}
                    alt="Left Arrow"
                    style={{
                      opacity: transitioning ? 0.5 : 1,
                      pointerEvents: transitioning ? "none" : "auto",
                    }}
                  />

                  <img
                    className="nav-arrow"
                    onClick={handleNextImage}
                    src={RightArrow}
                    alt="Right Arrow"
                    style={{
                      opacity: transitioning ? 0.5 : 1,
                      pointerEvents: transitioning ? "none" : "auto",
                    }}
                  />
                </div>
              </div>

              <div className="detail-cards">
                {/* Card 1 */}
                <div className="property-details-roi-card">
                  <h3>About {data.publicProperty.city.name}</h3>
                  <p>
                    {data.publicProperty.city.excerpt}
                  </p>
                  <div className="roi-container">
                    <div className="roi-content">
                      <img
                        src={RoiArrowUpIcon}
                        alt="Bath Icon"
                        className="icon"
                      />{" "}
                      <span>Investors</span>
                    </div>
                    <div className="roi-percentage">
                      {data.publicProperty.pledges?.filter(
                        (pledge: PropertyPledge) =>
                          pledge.status === PropertyPledgeStatus.Confirmed
                      ).length || 0}
                    </div>
                  </div>
                </div>{" "}
                {/* Card 2 */}
                <div className="calculator-card">
                  <CalculatorChart
                    investment={
                      (data.publicProperty.agreementPrice ||
                        data.publicProperty.price) / 100
                    }
                    // valueAppreciation={(data.publicProperty.agreementPrice || data.publicProperty.price) / 100 + (data.publicProperty.agreementPrice || data.publicProperty.price) / 100 * data.publicProperty.financials[0].annualAppreciation / 100 / 100 * 3}
                    valueAppreciation={
                      data.publicProperty.financials[0]
                        .year3ProjectedAnnualRoIValue / 100
                    }
                    annualIncome={
                      data.publicProperty.financials[0].annualRent / 100
                    }
                    years={3}
                    height={230}
                  />
                </div>
              </div>
            </div>
          )}
          <div>
            <TabComponent
              propertyDescription={data.publicProperty?.description}
              financialData={financialData}
              documents={documents}
              propertyId={data.publicProperty.id}
            />
          </div>
          <h2 className="documents-title">Location</h2>

          <div className="map">
            {isGoogleApiLoaded &&
              data &&
              data.publicProperty &&
              data.publicProperty.city && (
                <GoogleMap
                  ref={map as React.RefObject<GoogleMap>}
                  mapTypeId="roadmap"
                  mapContainerStyle={{
                    flex: 10,
                    height: 300,
                    width: "100%",
                    borderWidth: 1,
                  }}
                  center={
                    marker && marker.position
                      ? marker.position
                      : {
                          lat: data?.publicProperty.location.coordinates[1],
                          lng: data?.publicProperty.location.coordinates[0],
                        }
                  }
                  zoom={12}
                  options={{
                    mapId: "f93dc101cf6ad224",
                    disableDefaultUI: true,
                    zoomControl: true,
                  }}
                  onLoad={onMapLoad}
                ></GoogleMap>
              )}
          </div>
        </Content>
      ) : null}

      <Footer />
    </>
  );
}

const MarkerContent = ({ id, title }: { id: string; title: string }) => {
  return (
    <div className="custom-marker">
      {/* Ellipses */}
      <img src={Ellipse1} className="ellipse ellipse-1" alt="Ellipse 1" />
      <img src={Ellipse2} className="ellipse ellipse-2" alt="Ellipse 2" />
      <img src={Ellipse3} className="ellipse ellipse-3" alt="Ellipse 3" />

      {/* Marker Icon */}
      <img src={MapPointer} className="marker-icon" alt="Marker Icon" />

      {/* Label */}
      <div className="marker-label">{title}</div>
    </div>
  );
};

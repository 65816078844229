import { Content } from "../../components/content/content";
import { Footer } from "../../components/footer/footer";
import { Nav } from "../../components/nav/nav";

import { Helmet } from "react-helmet";
import { Bkg } from "../../components/content/bkg";
import { AppRoute } from "../../routes";
import { Link } from "react-router-dom";

export function CustomerSupportPage() {
  return (
    <>
      <Helmet>
        <title>Customer Support</title>
        <meta
          name="description"
          content="Customer support for PropNerd. Contact us for any questions or assistance."
        />
      </Helmet>

      <Nav />

      <Bkg type='bottom' />

      <Content className="static">
        <h2>Customer Support</h2>

        <div className="text-content" style={{ maxWidth: 500 }}>
          <p>At PropNerd, we're dedicated to ensuring you have the best experience on our platform. Our <Link to={AppRoute.Faq}>FAQ page</Link> covers the most frequently asked questions, so be sure to check it out if you need quick answers.</p>
          <p>If you have any other questions or need a helping hand, our friendly customer support team is always here for you. Feel free to reach out to us at <a href="mailto:support@propnerd.io">support@propnerd.io</a> - we're happy to assist!</p>
        </div>
      </Content>

      <Footer />
    </>

  );
}
import React from 'react'
import { useLocation } from 'react-router-dom'
import { GAService } from '../services/ga.service'

export function useAnalytics() {
  const location = useLocation()

  React.useEffect(() => {
    GAService.init()
  }, [])

  React.useEffect(() => {
    const path = location.pathname + location.search
    GAService.sendPageview(path)
  }, [location])
}

import { useEffect, useState } from "react";
import { Content } from "../../../components/content/content";
import { Footer } from "../../../components/footer/footer";
import { Nav } from "../../../components/nav/nav";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { Helmet } from "react-helmet";
import PhoneInputWithCountrySelect, { isValidPhoneNumber } from "react-phone-number-input";
import { Bkg } from "../../../components/content/bkg";
import { t } from "../../../utils/cms";
import CountryList from '../../../assets/json/countries.json';
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const PrioritisedCountries = [
  "GB",
  "US",
  "CA",
  "FR",
  "DE",
  "IT",
  "ES",
]

const Countries = PrioritisedCountries.map(code => ({
  code,
  name: CountryList.find(country => country.code === code)?.name
})).concat(CountryList.filter(country => !PrioritisedCountries.includes(country.code)).map(country => ({
  code: country.code,
  name: country.name
})));

export function AccountRegisterPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("UK");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [infoMessage, setInfoMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isQualifiedInvestor, setIsQualifiedInvestor] = useState(false); // New state for checkbox

  const [register, { data: registerData, error: registerError }] = useMutation(
    gql`
      mutation registerUser(
        $email: String!
        $phone: String!
        $password: String!
        $firstName: String!
        $lastName: String!
        $country: String!
        $investmentDuration: Float
        $investmentApproach: String
      ) {
        register(
          data: {
            email: $email
            phone: $phone
            password: $password
            firstName: $firstName
            lastName: $lastName
            country: $country
            investmentDuration: $investmentDuration
            investmentApproach: $investmentApproach
          }
        ) {
          id
          firstName
          lastName
          email
        }
      }
    `,
    { errorPolicy: "all" }
  );

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function validateEmail(emailTest: string) {
    return emailRegex.test(emailTest);
  }


  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setInfoMessage(undefined);
    setErrorMessage(undefined);
    setLoading(true);
    setSubmitted(false);

    // Validate form fields
    if (!firstName || !lastName || !email || !phone || !password || !country || !isQualifiedInvestor) {
      setLoading(false);
      setErrorMessage(t("accountRegister.Form Feedback.form_validation_error_account_register"));
      return;
    }
    if (!validateEmail(email)) {
      setLoading(false);
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    if (password.length < 8) {
      setLoading(false);
      setErrorMessage("Password must be at least 8 characters long.");
      return;
    }

    // Validate phone number
    if (!isValidPhoneNumber(phone)) {
      setLoading(false);
      setErrorMessage(t("accountRegister.Form Feedback.form_phone_error_account_register"));
      return;
    }

    setInfoMessage(t("accountRegister.Form Feedback.form_info_message_account_register"));

    // Submit form
    const data = {
      firstName,
      lastName,
      phone,
      password,
      email,
      country,
      investmentDuration: undefined,
      investmentApproach: undefined,
    };

    register({ variables: data });
  }

  useEffect(() => {
    if (registerError) {
      console.error("Registration error:", registerError);
      setLoading(false);
      setInfoMessage(undefined);
      setErrorMessage(registerError.message);
    } else if (registerData) {
      setLoading(false);
      setSubmitted(true);
      setInfoMessage("Your request has been submitted successfully. Please check your email for the next steps.");
    }
  }, [registerData, registerError]);

  return (
    <>
      <Helmet>
        <title>{t("accountRegister.Helmet.helmet_title_account_register")}</title>
        <meta name={t("accountRegister.Helmet.helmet_meta_name_account_register")} content={t("accountRegister.Helmet.helmet_meta_content_account_register")} />
      </Helmet>

      <Nav />

      <Bkg type="bottom" />

      <Content className="account flex flex-column">
        <h2>{t("accountRegister.Intro.intro_heading_account_register")}</h2>
        <p>{t("accountRegister.Intro.intro_description_account_register")}</p>
        <form className="flex flex-1 flex-column gap mb-5" onSubmit={handleSubmit}>
          <h3>{t("accountRegister.Form General.form_heading_account_register")}</h3>
          {infoMessage && <div className="info-message">{infoMessage}</div>}
          {errorMessage && <div className="error-message">{errorMessage}</div>}

          {!registerData ? (
            <>
              <div className="flex flex-column">
                <label htmlFor="first-name">{t("accountRegister.Form Fields First Name.first_name_label_account_register")}</label>
                <input
                  type="text"
                  id="first-name"
                  name="first-name"
                  placeholder={t("accountRegister.Form Fields First Name.first_name_placeholder_account_register")}
                  autoFocus
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                />
              </div>
              <div className="flex flex-column">
                <label htmlFor="last-name">{t("accountRegister.Form Fields Last Name.last_name_label_account_register")}</label>
                <input
                  type="text"
                  id="last-name"
                  name="last-name"
                  placeholder={t("accountRegister.Form Fields Last Name.last_name_placeholder_account_register")}
                  onChange={(e) => setLastName(e.currentTarget.value)}
                />
              </div>
              <div className="flex flex-column">
                <label htmlFor="email">{t("accountRegister.Form Fields Email.email_label_account_register")}</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder={t("accountRegister.Form Fields Email.email_placeholder_account_register")}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </div>
              <div className="flex flex-column">
                <label htmlFor="password">{t("accountRegister.Form Fields Password.password_label_account_register")}</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder={t("accountRegister.Form Fields Password.password_placeholder_account_register")}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                />
              </div>
              <div className="flex flex-column">
                <label htmlFor="phone">{t("accountRegister.Form Fields Phone.phone_label_account_register")}</label>
                <PhoneInputWithCountrySelect
                  defaultCountry="GB"
                  placeholder={t("accountRegister.Form Fields Phone.placeholder_phone_account_register")}
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e ? e.toString() : "")}
                />
              </div>
              <div className="flex flex-column">
                <label htmlFor="country">{t("accountRegister.Form Fields Country.label_country_residence_account_register")}</label>
                <select
                  id="country"
                  name="country"
                  onChange={(e) => setCountry(e.currentTarget.value)}
                >
                  {Countries.map((country) => (
                    <option key={country.code} value={country.code}>{country.name}</option>
                  ))}
                </select>
              </div>

              {/* New Checkbox for Qualified Investor */}
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="qualified-investor"
                  name="qualified-investor"
                  checked={isQualifiedInvestor}
                  onChange={() => setIsQualifiedInvestor(!isQualifiedInvestor)}
                  className="custom-checkbox"
                />
                <label htmlFor="qualified-investor" className="checkbox-label">
                  I confirm I am a suitably qualified investor. 
                  <a href="/qualified-investor" className="learn-more-link" target="_blank" rel="noopener noreferrer">Learn more</a>
                </label>
              </div>


              <div className="actions">
                <button type="submit" className="btn btn-primary btn-square" disabled={loading}>
                  {loading ? t("accountRegister.Form Buttons.form_submit_button_loading_text_account_register") : t("accountRegister.Form Buttons.form_submit_button_text_account_register")}
                </button>
              </div>
            </>
          ) : (
            <>
              {/* <h3>{t("accountRegister.Next Steps.next_steps_heading_account_register")}</h3>
              <p>{t("accountRegister.Next Steps.next_steps_description_account_register")}</p>
              <ul>
                <li>
                  <b>{t("accountRegister.Next Steps Items Identity Document.title_identity_document_account_register")}</b> {t("accountRegister.Next Steps Items Identity Document.description_identity_document_account_register")}
                </li>
                <li>
                  <b>{t("accountRegister.Next Steps Items Selfie Verification.title_selfie_verification_account_register")}</b> {t("accountRegister.Next Steps Items Selfie Verification.description_selfie_verification_account_register")}
                </li>
              </ul>
              <p>{t("accountRegister.Next Steps.next_steps_closing_account_register")}</p>
              <p>{t("accountRegister.Next Steps.next_steps_privacy_account_register")}</p> */}
            </>
          )}
        </form>
      </Content>

      <Footer />
    </>
  );
}

import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { PropertyCard } from '../../../components/property/card';
import { QUERY_GET_PROPERTY_LIST } from '../../../graphql/queries/property';
import { useLazyQuery } from '@apollo/client';
import ScrollAnimationComponent from '../../../components/animation/scroll-animation.component';

interface Props {
  className?: string;
}

export function Carousel({ className }: Props) {
  const [getProperties, { loading, error, data }] = useLazyQuery(QUERY_GET_PROPERTY_LIST, { errorPolicy: 'all' });
  const [properties, setProperties] = useState<any[]>();

  useEffect(() => {
    getProperties({ variables: { take: 6, skip: 0, where: { filters: [{ field: 'status', operator: 'in', value: '["active", "coming_soon"]' }]}}});
  }, []);

  useEffect(() => {
    if (data) {
      setProperties([...data.publicProperties]);
    }
  }, [data]);

  return (
    <div className={classNames('carousel', className)}>
      { properties?.map((property: any) =>
      <ScrollAnimationComponent animationName='fadeInUp' key={property.id}>
        <PropertyCard className={classNames(className)} key={property.id} property={property} showFinancials={true} />
      </ScrollAnimationComponent>
      )}
    </div>
  );
}

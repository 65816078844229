import React, { useState } from "react";
import ArrowDownIcon from "../../assets/svgs/direction-down.svg"; // Replace with actual path to your SVG
import ArrowUpIcon from "../../assets/svgs/arrow-up.svg"; // Replace with actual path to your SVG
import Financials from "./financials";
import Documents from "./documents";
import { htmlParagraphs } from "../../utils/string.util";


interface FinancialDataProps {
  propertyPrice: string;
  transactionCosts: string;
  platformFee: string;
  investmentCost: string;
  annualGrossRent: string;
  serviceCharges: string;
  maintainCharges: string;
  annualNetIncome: string;
}
interface Document {
  id: string;
  filename: string;
  url: string;
}

interface TabComponentProps {
  propertyDescription: string;
  financialData: FinancialDataProps;
  documents: Document[];
  propertyId: string;


}


const TabComponent: React.FC<TabComponentProps> = ({ propertyDescription,financialData,documents, propertyId }) => {
  const [activeTab, setActiveTab] = useState("Details");
  const [isExpanded, setIsExpanded] = useState(false);

  const renderContent = () => {
    switch (activeTab) {
      case "Details":
        return (
          <div className="tab-details">
            <h3 className="tab-details-title">Property Description</h3>
            <div className={`tab-details-text ${isExpanded ? 'expanded' : 'collapsed'}`} dangerouslySetInnerHTML={{ __html: htmlParagraphs(propertyDescription) }} />
            <div
              className="read-more-toggle"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <img
                src={isExpanded ? ArrowDownIcon : ArrowDownIcon}
                alt={isExpanded ? "Read Less" : "Read More"}
                className={`${isExpanded ? 'rotate-180' : ''}`}
              />
              <span>{isExpanded ? "Read Less" : "Read More"}</span>
            </div>
          </div>
        );
      case "Financials":
        return <Financials financialData={financialData}/>;
      case "Timelines":
        return <div className="tab-content">Timelines Content</div>;
      case "Documents":
        return <Documents documents={documents} propertyId={propertyId}/>;
      default:
        return null;
    }
  };

  return (
    <div className="tab-container">
      <div className="tab-buttons">
        <button
          className={`tab-button ${activeTab === "Details" ? "active" : ""}`}
          onClick={() => setActiveTab("Details")}
        >
          Details
        </button>
        <button
          className={`tab-button ${activeTab === "Financials" ? "active" : ""}`}
          onClick={() => setActiveTab("Financials")}
        >
          Financials
        </button>
        <button
          className={`tab-button ${activeTab === "Documents" ? "active" : ""}`}
          onClick={() => setActiveTab("Documents")}
        >
          Documents
        </button>
      </div>
      <div className="tab-content-container">{renderContent()}</div>
    </div>
  );
};

export default TabComponent;

import { ReactComponent as ImgAppStore } from '../../../assets/img/common/app-store.svg';
import { ReactComponent as ImgPlayStore } from '../../../assets/img/common/play-store.svg';
import { ReactComponent as ImgSection3QR } from '../../../assets/img/home/section_3_qr.svg';
import { t } from '../../../utils/cms';
const ImgPhone = require('../../../assets/img/home/hero_phone.webp');

export function HomeDownloadAppSection() {
  return (
    <div className="hero-content">
      <div className="hero-text">
        <h1 className="animate__animated animate__backInLeft">{t('homeDownloadApp.App Download Overview.download_app_heading_home')}</h1>
        <p className="animate__animated animate__backInLeft animate__delay-1s">{t('homeDownloadApp.App Download Overview.download_app_description_home')}</p>
        <div className="qr"><ImgSection3QR /></div>
        <div className="store-links m-justify-center flex gap-lg">
          <a href="https://apps.apple.com/app/propnerd/id6497331931" target='_blank' className="animate__animated animate__backInLeft animate__delay-2s">
            <ImgAppStore width={150} height={50}  />
          </a>
          <a href="https://play.google.com/store/apps/details?id=io.propnerd.investor" target='_blank' className="animate__animated animate__backInLeft animate__delay-2s">
            <ImgPlayStore width={158} height={50} className="animate__animated animate__backInLeft animate__delay-2s"  />
          </a>
        </div>
      </div>
      <div className="hero-img animate__animated animate__backInRight animate__delay-1s">
        <img src={ImgPhone} className="display-pic" alt={t('homeDownloadApp.App Download Visuals.phone_image_alt_text_home')} />
      </div>
    </div>
  )
}
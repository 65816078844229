import ScrollAnimationComponent from "../../../components/animation/scroll-animation.component";
import { ReactComponent as ImgBottomFeatureGrowth } from '../../../assets/img/home/icon/bottom_feature_growth.svg';
import { ReactComponent as ImgBottomFeatureLuxury } from '../../../assets/img/home/icon/bottom_feature_luxury.svg';
import { ReactComponent as ImgBottomFeatureSecure } from '../../../assets/img/home/icon/bottom_feature_secure.svg';
import { ReactComponent as ImgBottomFeatureSimplified } from '../../../assets/img/home/icon/bottom_feature_simplified.svg';
import { t } from "../../../utils/cms";
export function HomeJoinSection() {
  return (
    <div className="join">
      <ScrollAnimationComponent animationName="backInDown">
        <h4>{t('homeJoin.Join Overview.join_heading_home')}</h4>
      </ScrollAnimationComponent>
      <ScrollAnimationComponent className="join-features" animationDelay="fadeInDownBig">
        <div>
          <ImgBottomFeatureLuxury />
          <h5>{t('homeJoin.Own a Premium Property.own_premium_property_title_home')}</h5>
          <p>{t('homeJoin.Own a Premium Property.own_premium_property_description_home')}</p>
        </div>
        <div>
          <ImgBottomFeatureGrowth />
          <h5>{t('homeJoin.Balance Income and Growth.balance_income_growth_title_home')}</h5>
          <p>{t('homeJoin.Balance Income and Growth.balance_income_growth_description_home')}</p>
        </div>
        <div>
          <ImgBottomFeatureSimplified />
          <h5>{t('homeJoin.Premium Investment Simplified.premium_investment_simplified_title_home')}</h5>
          <p>{t('homeJoin.Premium Investment Simplified.premium_investment_simplified_description_home')}</p>
        </div>
        <div>
          <ImgBottomFeatureSecure />
          <h5>{t('homeJoin.Secure and Transparent.secure_transparent_title_home')}</h5>
          <p>{t('homeJoin.Secure and Transparent.secure_transparent_description_home')}</p>
        </div>
      </ScrollAnimationComponent>
    </div>
  )
}
import React, { useState } from "react";
import { getPropertyImageUrl } from "../../utils/image.utils"; // Adjust the import path as necessary

interface SmallImagesProps {
  images: any[];
  propertyId: string;
  onImageSelect: (image: any) => void;
  selectedImage: any;
  containerRef: React.RefObject<HTMLDivElement>;

}

const SmallImages: React.FC<SmallImagesProps> = ({
  images,
  propertyId,
  onImageSelect,
  selectedImage,
  containerRef
}) => {
  return (
    <div className="small-images" ref={containerRef}>
      {images.map((image) => {
        if (!image || !image.id) return null;

        return (
          <img
            key={image?.id}
            className={`small-image ${
              selectedImage? selectedImage.id == image.id ? "selected" : "" :""
            }`}
            src={getPropertyImageUrl(propertyId, image, undefined, 605)}
            alt="Property"
            onClick={() => onImageSelect(image)}
          />
        );
      })}
    </div>
  );
};

export default SmallImages;

import { MouseEvent, useRef, useState } from "react";
import { ReactComponent as ImgAppStore } from '../../../assets/img/common/app-store.svg';
import { ReactComponent as ImgPlayStore } from '../../../assets/img/common/play-store.svg';
import { ReactComponent as ImgIconPlay } from '../../../assets/img/home/icon/play.svg';import { t } from "../../../utils/cms";
const ImgPhone = require('../../../assets/img/home/hero_phone.webp');


export function HomeHeroSection() {
  const userAgent = navigator.userAgent.toLowerCase();
  const videoRef = useRef<HTMLIFrameElement>(null);
  const [showVideo, setShowVideo] = useState(false);
  const isMobile = /iphone|ipad|ipod|android|windows phone/g.test(userAgent);

  function toggleVideo(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    setShowVideo(!showVideo);

    setTimeout(() => {
      if (videoRef.current && isMobile) {
        videoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 20);
  }

  return (
    <div className="hero-content">
      <div className="hero-text">
        <h1 className="animate__animated animate__backInLeft">{t('homeHero.hero section.heading_next_gen_investments')}</h1>
        <div className="hero-line"></div>
        {/* <p className="animate__animated animate__backInLeft animate__delay-1s">{t('homeHero.hero section.description_own_piece_market')}<em>{t('homeHero.hero section.description_own_piece_market1')}</em>{t('homeHero.hero section.description_own_piece_market2')}<em>{t('homeHero.hero section.description_own_piece_market3')}</em>{t('homeHero.hero section.description_own_piece_market4')}</p>
        <p className="animate__animated animate__backInLeft animate__delay-1s">{t('homeHero.hero section.description_community_of_investors')}<em>{t('homeHero.hero section.description_community_of_investors1')}</em>{t('homeHero.hero section.description_community_of_investors2')}</p> */}
        <p className="animate__animated animate__backInLeft animate__delay-1s">PropNerd is a platform that lets you <em>own a small or large share</em> of real estate, helping you <em>earn passive income</em> easily.</p>
        <p className="animate__animated animate__backInLeft animate__delay-1s">We're creating a community of investors, making it simple for <em>everyone to build wealth</em> through smart real estate investments.</p>
        <a
          href="https://storage.googleapis.com/propnerd-assets/common/propnerd_explainer.mp4"
          target="_blank"
          rel="noreferrer"
          className="video-link animate__animated animate__backInLeft animate__delay-1s"
          onClick={toggleVideo}
        >
          <span><ImgIconPlay /></span> {t('homeHero.hero section.video_link_text_how_it_works')}
        </a>
        <div className="store-links m-justify-center flex gap-lg">
          <a
            href="https://apps.apple.com/app/propnerd/id6497331931"
            target='_blank'
            className="animate__animated animate__backInLeft animate__delay-2s"
          >
            <ImgAppStore width={150} height={50}  />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=io.propnerd.investor"
            target='_blank'
            className="animate__animated animate__backInLeft animate__delay-2s"
          >
            <ImgPlayStore width={158} height={50}  />
          </a>
        </div>
      </div>
      {showVideo && <div className="hero-video">
        {/* <iframe ref={videoRef} width="100%" height="auto" src="https://storage.googleapis.com/propnerd-assets/common/propnerd_explainer.mp4" title="PropNerd: How it Works" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe> */}
        <video
          src="https://storage.googleapis.com/propnerd-assets/common/propnerd_explainer.mp4"
          width={'100%'}
          autoPlay
          controls
          preload="metadata"
          onEnded={() => {setShowVideo(false)}}
        />
        {/* <YouTube
          videoId='_w8yAZiv8Sc'
          title='PropNerd: How it Works'
          opts={{ playerVars: { autoplay: 1, rel: 0 }}}
          onEnd={() => {setShowVideo(false)}}
        /> */}
      </div>}
      {!showVideo && <div className="hero-img animate__animated animate__backInRight animate__delay-1s">
      <img src={ImgPhone} className="display-pic" alt={t('homeHero.hero section.phone_image_alt_text')} />
    </div>}
    </div>
  )
}
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { AboutPage } from "./pages/about/about";
import { AccountLoginPage } from "./pages/account/login/login";
import { AccountRegisterPage } from "./pages/account/register/register";
import { AccountVerifyPage } from "./pages/account/verify/verify";
import { Error404Page } from "./pages/error/404";
import { FaqPage } from "./pages/faq/faq";
import { HomePage } from "./pages/home/home";
import { HowItWorksPage } from "./pages/how/how";
import { PrivacyPage } from "./pages/privacy/privacy";
import { PropertyMarketplacePage } from "./pages/properties/marketplace/marketplace";
import { PropertySubmitPage } from "./pages/properties/submit/submit";
import { RisksPage } from "./pages/risks/risks";
import { ServicesPage } from "./pages/services/services";
import { TermsPage } from "./pages/terms/terms";
import { DisclaimerPage } from "./pages/disclaimer/disclaimer";
import { WelcomePage } from "./pages/welcome/welcome";
import { PropertyDetailsPage } from "./pages/properties/marketplace/property-page";
import { QualifiedInvestorPage } from "./pages/account/qualifiedInvestor/qualified-investor";
import { CustomerSupportPage } from "./pages/customer-support/customer-support";

export enum AppRoute {
  Home = "/",
  About = "/about",
  HowItWorks = "/how-it-works",
  Property = "/property/:slug/:id",
  PropertyDetails = "/property-details/:slug/:id",
  PropertyMarketplace = "/property/marketplace",
  PropertySubmit = "/property/submit",
  Services = "/services",
  Faq = "/faq",
  CustomerSupport = "/customer-support",
  AccountLogin = "/account/login",
  AccountRegister = "/account/register",
  AccountVerify = "/account/register/verify",
  Terms = "/terms",
  Privacy = "/privacy",
  Disclaimer = "/disclaimer",
  Risk = "/risks",
  Welcome = "/welcome",
  QualifiedInvestor = "/qualified-investor",
}

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path={AppRoute.Home} element={<HomePage />} />,
    <Route path={AppRoute.About} element={<AboutPage />} />,
    <Route path={AppRoute.HowItWorks} element={<HowItWorksPage />} />,
    <Route
      path={AppRoute.PropertyMarketplace}
      element={<PropertyMarketplacePage />}
    />,
    <Route path={AppRoute.Property} element={<PropertyDetailsPage />} />,
    <Route path={AppRoute.PropertySubmit} element={<PropertySubmitPage />} />,
    <Route path={AppRoute.Services} element={<ServicesPage />} />,
    <Route path={AppRoute.Faq} element={<FaqPage />} />,
    <Route path={AppRoute.CustomerSupport} element={<CustomerSupportPage />} />,
    <Route path={AppRoute.AccountLogin} element={<AccountLoginPage />} />,
    <Route path={AppRoute.AccountRegister} element={<AccountRegisterPage />} />,
    <Route path={AppRoute.AccountVerify} element={<AccountVerifyPage />} />,
    <Route path={AppRoute.Terms} element={<TermsPage />} />,
    <Route path={AppRoute.Privacy} element={<PrivacyPage />} />,
    <Route path={AppRoute.Disclaimer} element={<DisclaimerPage />} />,
    <Route path="*" element={<Error404Page />} />,
    <Route path={AppRoute.Welcome} element={<WelcomePage />} />,
    <Route path={AppRoute.QualifiedInvestor} element={<QualifiedInvestorPage />} />,
  ])
);

export const AppRoutes = () => <RouterProvider router={router} />;

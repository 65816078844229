import { Link } from "react-router-dom";
import ScrollAnimationComponent from "../../../components/animation/scroll-animation.component";
import { AppRoute } from "../../../routes";
import {t } from "../../../utils/cms";
export function HomeGetStartedSection() {
  return (
    <div className="get-started">
    <div className="get-started-content">
      <ScrollAnimationComponent animationName="fadeInLeftBig" style={{ height: 'auto' }}>
        <h4>{t('homeGetStarted.introduction.heading_home_get_started')}</h4>
      </ScrollAnimationComponent>
      <ScrollAnimationComponent animationName="fadeInLeftBig">
        <p>{t('homeGetStarted.introduction.description_home_get_started')}</p>
        <h4 className="fasteasy"><b>{t('homeGetStarted.key features.subheading_home_get_started')}</b></h4>
        <Link className="btn-started" to={AppRoute.AccountRegister}>{t('homeGetStarted.call to action.button_text_home_get_started')}</Link>
      </ScrollAnimationComponent>
    </div>
  </div>
  )
}
import React from "react";

interface FinancialDataProps {
  propertyPrice: string;
  transactionCosts: string;
  platformFee: string;
  investmentCost: string;
  annualGrossRent: string;
  serviceCharges: string;
  maintainCharges: string;
  annualNetIncome: string;
}

const Financials: React.FC<{ financialData: FinancialDataProps }> = ({
  financialData,
}) => {
  return (
    <div className="financials-container">
      <div className="financials-header">
        <h3 className="financials-title">Property Financials</h3>
        <div className="financials-estimation">
          <span className="dot"></span>
          Estimation of 1 Year
        </div>
      </div>

      <div className="financials-grid">
        <div className="financials-section">
          <h3 className="financials-subtitle">Property Cost</h3>
          <div className="separation-line"></div>

          <div className="financials-item">
            <span>Property Price</span>
            <span className="financials-value">
              {financialData.propertyPrice}
            </span>
          </div>
          <div className="financials-item">
            <span>Transaction Costs</span>
            <span className="financials-value">
              {financialData.transactionCosts}
            </span>
          </div>
          <div className="financials-item">
            <span>PropNerd Fee</span>
            <span className="financials-value">
              {financialData.platformFee}
            </span>
          </div>
          <div className="separation-line"></div>
          <div className="financials-item">
            <span className="financials-item-bold">Investment Cost</span>
            <span className="financials-value">
              {financialData.investmentCost}
            </span>
          </div>
        </div>

        <div className="vertical-line"></div>

        <div className="financials-section">
          <h3 className="financials-subtitle">Rental income (Year 1)</h3>
          <div className="separation-line"></div>

          <div className="financials-item">
            <span>Annual gross rent</span>
            <span className="financials-value">
              {financialData.annualGrossRent}
            </span>
          </div>
          <div className="financials-item">
            <span>Service Charges</span>
            <span className="financials-value">
              ({financialData.serviceCharges})
            </span>
          </div>
          <div className="financials-item">
            <span>Mgmt / Maint.</span>
            <span className="financials-value">
              ({financialData.maintainCharges})
            </span>
          </div>
          <div className="separation-line"></div>
          <div className="financials-item">
            <span className="financials-item-bold">Annual net income</span>
            <span className="financials-value">
              {financialData.annualNetIncome}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Financials;

import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef, useState } from "react";

interface Props {
  title: string;
  defaultValue?: number;
  snapPoints?: number[];
  valueFn?: (percentage: number) => string;
  onChange?: (percentage: number) => void;
}

export function Slider(props: Props) {
  const { title, defaultValue, snapPoints, valueFn, onChange } = props;

  const valueRef = useRef<HTMLDivElement>(null);
  const baseLineRef = useRef<HTMLDivElement>(null);
  const centerOffset = useRef(0);
  const [valueX, setValueX] = useState(0);
  const [percentage, setPercentage] = useState(defaultValue || 0);

  
  const debouncedOnChange: (percentage: number) => void = useCallback(debounce(onChange || (() => {}), 500), [onChange]); // eslint-disable-line
  
  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('touchmove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('touchend', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    }
  }, []);

  useEffect(() => {
    if (valueRef) {
      centerOffset.current = valueRef.current?.offsetWidth ? valueRef.current.offsetWidth / 2 : 0;
      if (defaultValue) {
        setValueX(getValueXFromPercentage(defaultValue));
      }
    }
  }, [valueRef]) // eslint-disable-line

  useEffect(() => {
    debouncedOnChange?.(percentage);
  }, [percentage, debouncedOnChange]);

  function getValueXFromPercentage(percentage: number): number {
    const min = 0;
    const max = (baseLineRef.current ? baseLineRef.current.offsetWidth : 0) - (2 * centerOffset.current);
    return (percentage / 100) * (max - min) + min;
  }

  function onMouseMove(e: MouseEvent | TouchEvent) {
    if (baseLineRef.current?.getAttribute('dragging') === 'true') {
      const baseLineRect = baseLineRef.current?.getBoundingClientRect();
      
      const min = 0;
      const max = (baseLineRect ? baseLineRect.width : 0) - (2 * centerOffset.current);
      let positionX = 'clientX' in e ? 
        e.clientX - centerOffset.current - (baseLineRect ? baseLineRect.left : 0):
        e.touches[0].clientX - centerOffset.current - (baseLineRect ? baseLineRect.left : 0);
      
      // Constrain the X position within the min and max bounds
      if (positionX < min) positionX = min;
      if (positionX > max) positionX = max;

      // Calculate raw percentage based on positionX
      let rawPercentage = (positionX - min) / (max - min) * 100;

      // Determine if within 2% of a snap point (10% intervals)
      let nearestSnapPoint = Math.round(rawPercentage / 10) * 10;
      let distanceToSnapPoint = Math.abs(rawPercentage - nearestSnapPoint);

      // Handle snap points
      if (snapPoints && snapPoints.length > 0) {
        nearestSnapPoint = snapPoints.reduce((prev, curr) => {
          return (Math.abs(rawPercentage - curr) < Math.abs(rawPercentage - prev) ? curr : prev);
        });
        distanceToSnapPoint = Math.abs(rawPercentage - nearestSnapPoint);
      }

      // Snap to nearest 10% point if within 2%
      setValueX(getValueXFromPercentage(nearestSnapPoint));
      setPercentage(nearestSnapPoint);


      // if (distanceToSnapPoint <= 2) {
      //   // Snap to nearest 10% point if within 2%
      //   setValueX((nearestSnapPoint / 100) * (max - min) + min);
      //   setPercentage(nearestSnapPoint);
      // } else {
      //   // Allow manual dragging
      //   setValueX(positionX);
      //   setPercentage(Math.round(rawPercentage));
      // }
    }
  }


  function onMouseUp() {
    if (baseLineRef.current?.getAttribute('dragging') === 'true') {
      baseLineRef.current?.removeAttribute('dragging');
    }
  }

  function onMouseDown() {
    baseLineRef.current?.setAttribute('dragging', 'true')
  }

  function getValue() {
    if (valueFn) {
      return valueFn(percentage);
    }
    return `${percentage}%`;
  }

  return (
    <div className="calculator-item">
      <div className='calculator-item-header'>
        <h5>{ title }</h5>
        <h6>{ getValue() }</h6>
      </div>
      <div className="calculator-input">
        <div ref={baseLineRef} className="calculator-input-baseline"></div>
        <div className="calculator-input-valueline" style={{ width: `${valueX}px`}}></div>
        <div ref={valueRef} className="calculator-input-value" style={{ left: `${valueX}px` }} onMouseDown={onMouseDown} onTouchStart={onMouseDown}></div>
        <div className="calculator-input-value-hint"></div>
        <div className="calculator-input-min"></div>
        <div className="calculator-input-max"></div>
      </div>
    </div>
  )
}
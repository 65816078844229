import { Content } from "../../components/content/content";
import { Footer } from "../../components/footer/footer";
import { Nav } from "../../components/nav/nav";
import { t } from "../../utils/cms";
import { Helmet } from "react-helmet";
import ImgHero1 from '../../assets/img/about/hero_1.png';
import ImgHero2 from '../../assets/img/about/hero_2.png';
import ImgHero3 from '../../assets/img/about/hero_3.png';
import { Bkg } from "../../components/content/bkg";

import { ReactComponent as ImgVision } from '../../assets/img/about/vision.svg';
import { ReactComponent as ImgMission } from '../../assets/img/about/mission.svg';
import { ReactComponent as ImgInvestor } from '../../assets/img/about/investor.svg';

export function AboutPage() {
  return (
    <>
      <Helmet>
        <title>{t('about.Helmet Information.helmet_title_about')}</title>
        <meta name={t('about.Helmet Information.helmet_meta_name_about')} content={t('about.Helmet Information.helmet_meta_content_about')} />
      </Helmet>

      <Nav />

      <Bkg />

      <Content className="about">
        <div className="subheader">
          <h3 dangerouslySetInnerHTML={{ __html: t('about.Subheader.subheader_title_about') }} />
          <p>{t('about.Subheader.subheader_description_about')}</p>
          <img src={ImgHero1} className="hero-img hero-img-1" alt={t('about.Subheader.subheader_hero_images_alt_about')} />
          <img src={ImgHero2} className="hero-img hero-img-2" alt={t('about.Subheader.subheader_hero_images_alt_about')} />
          <img src={ImgHero3} className="hero-img hero-img-3" alt={t('about.Subheader.subheader_hero_images_alt_about')} />
          <div className="hero-m-imgs">
            <img src={ImgHero1} className="hero-m-img" alt={t('about.Subheader.subheader_hero_images_alt_about')} />
            <img src={ImgHero2} className="hero-m-img" alt={t('about.Subheader.subheader_hero_images_alt_about')} />
            <img src={ImgHero3} className="hero-m-img" alt={t('about.Subheader.subheader_hero_images_alt_about')} />
          </div>
        </div>

        <p className="subheader2">{t('about.Subheader.subheader_2_about')}</p>

        <div className="vismis flex m-flex-column gap-lg mb-5 md:p-5 mt-5">
          <div className="flex-1">
            <ImgMission />
            <h4>{t('about.Mission and Vision.mission_title_about')}</h4>
            <p>{t('about.Mission and Vision.mission_description_about')}</p>
          </div>

          <div className="flex-1">
            <ImgVision />
            <h4>{t('about.Mission and Vision.vision_title_about')}</h4>
            <p>{t('about.Mission and Vision.vision_description_about')}</p>
          </div>
        </div>

        <div className="advantages">
          <h3>{t('about.Advantages Overview.advantages_title_about')}</h3>
          <div className="flex gap-lg m-flex-column">
            <div>
              <ImgInvestor />
              <h4>{t('about.Advantages for Retail Investors.advantages_retail_investors_title_about')}</h4>
              <div className="card-content">
                <div>
                  <h5>{t('about.Advantages for Retail Investors.unprecedented_access_title_about')}</h5>
                  <p>{t('about.Advantages for Retail Investors.description')}</p>
                </div>
                <div>
                  <h5>{t('about.Advantages for Retail Investors.intelligent_investing_title_about')}</h5>
                  <p>{t('about.Advantages for Retail Investors.intelligent_investing_description_about')}</p>
                </div>
                <div>
                  <h5>{t('about.Advantages for Retail Investors.effortless_ownership_title_about')}</h5>
                  <p>{t('about.Advantages for Retail Investors.effortless_ownership_description_about')}</p>
                </div>
                <div>
                  <h5>{t('about.Advantages for Retail Investors.seamless_asset_management_title_about')}</h5>
                  <p>{t('about.Advantages for Retail Investors.seamless_asset_management_description_about')}</p>
                </div>
              </div>
            </div>

            <div>
              <ImgInvestor />
              <h4>{t('about.Advantages for Property Developers.for_property_developers_title_about')}</h4>
              <div className="card-content">
                <div>
                  <h5>{t('about.Advantages for Property Developers.leading_with_blockchain_title_about')}</h5>
                  <p>{t('about.Advantages for Property Developers.leading_with_blockchain_description_about')}</p>
                </div>
                <div>
                  <h5>{t('about.Advantages for Property Developers.confident_transactions_title_about')}</h5>
                  <p>{t('about.Advantages for Property Developers.confident_transactions_description_about')}</p>
                </div>
                <div>
                  <h5>{t('about.Advantages for Property Developers.operational_efficiency_title_about')}</h5>
                  <p>{t('about.Advantages for Property Developers.operational_efficiency_description_about')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>

      <Footer />
    </>

  );
}
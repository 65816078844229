import { useEffect, useState } from 'react';
import { ReactComponent as ImgBkgMain } from '../../assets/img/home/bkg_main.svg';
import { ReactComponent as ImgBkgHouse } from '../../assets/img/home/bkg_house.svg';
import { ReactComponent as ImgBkgAbout } from '../../assets/img/home/bkg_about.svg';
import { ReactComponent as ImgBkgService } from '../../assets/img/home/bkg_service.svg';
import { useLocation } from 'react-router-dom';

interface Props {
  type?: 'default' | 'bottom',
}
export function Bkg(props: Props) {

  let Img: any;
  switch (props.type) {
    default:
      Img = ImgBkgMain;
  }

  const [documentHeight, setDocumentHeight] = useState(window.document.body.offsetHeight);

  const location = useLocation();
  
  function debounce(callback: () => void, delay: number) {
    let timer: ReturnType<typeof setTimeout>;

    return function debouncedFunc() {
      clearTimeout(timer);
      timer = setTimeout(callback, delay);
    };
  }

  const handleWindowResize = debounce(() => {
    setDocumentHeight(window.document.body.offsetHeight);
  }, 100);

  // useEffect(() => {
  //   window.addEventListener('resize', handleWindowResize);

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, []);

  useEffect(() => {
    handleWindowResize();
  }, [location, handleWindowResize]);

  return (
    <div className={`bkg-img ${props.type}`}>
      <Img width="100%" style={{ display: 'block' }} />
    </div>
  )
}
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { toHumanReadableCurrency } from '../../../utils/currency.util';

interface ChartData {
  year: number;
  investment: number;
  rentalIncome: number;
  valueAppreciation: number;
  propertyValue: number;
}

interface Props {
  investment: number;
  valueAppreciation: number;
  annualIncome: number;
  years?: number;
  height?: number;
}

export function CalculatorChart({ investment, valueAppreciation, annualIncome, years = 5, height = 400 }: Props) {

  const data: ChartData[] = [];
  
  // Initialize cumulative variables
  let cumulativeInvestment = investment;
  let cumulativeRentalIncome = 0;
  let cumulativePropertyValue = investment;
  let cumulativeValuation = 0;

  const formatYAxis = (value: number) => `£${(value / 1000).toFixed(0)}K`;

  // Calculate investment, rental income, and property value appreciation for each year
  for (let i = 0; i < years; i++) {
    const year = 2025 + i;

    // Update cumulative values
    cumulativeRentalIncome += annualIncome;
    cumulativePropertyValue += valueAppreciation / years;
    cumulativeValuation += valueAppreciation / years;

    // Add data for the current year to the array
    data.push({
      year,
      investment: Math.round(cumulativeInvestment),
      rentalIncome: Math.round(cumulativeRentalIncome),
      valueAppreciation: Math.round(valueAppreciation / years),
      propertyValue: Math.round(cumulativePropertyValue),
    });

    // Update cumulative investment
  }

  const customTooltip = ({ payload, label }: any) => {
    if (payload && payload.length) {
      return (
        <div style={{ backgroundColor: '#ffffff', padding: '8px', borderRadius: '8px', color: '#14223D' }}>
          <p style={{ color: '#14223D' , fontWeight: '700' }}>{`Year ${label}`}</p>
          <p style={{ color: '#14223D' , fontSize: '1rem' , fontWeight: '500' }}>{`Investment: £${(payload[0].value / 1000).toFixed(0)}K`}</p>
          <p style={{ color: '#b39600' , fontSize: '1rem' , fontWeight: '500'}}>{`Rental Income: £${(payload[1].value / 1000).toFixed(0)}K`}</p>
          <p style={{ color: '#18b259' , fontSize: '1rem' , fontWeight: '500'}}>{`Value Appreciation: £${(payload[2].value / 1000).toFixed(0)}K`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="calculator-chart">
      <h2>Projected ROI Chart</h2>
      <div className="chart-cards">
        <div className="chart-card">
          <h4>Total Investment</h4>
          <p className="chart-heading">{toHumanReadableCurrency(cumulativeInvestment * 100)}</p>
        </div>
        <div className="chart-card">
          <h4>Projected Rental Income</h4>
          <p className="chart-heading">{toHumanReadableCurrency(cumulativeRentalIncome * 100)}</p>
        </div>
        <div className="chart-card">
          <h4>Projected {years} Yr ROI</h4>
          <p className="chart-heading">{toHumanReadableCurrency(cumulativeValuation * 100)}</p>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={height}>
        <BarChart data={data}>
          <XAxis dataKey="year" tick={{ fontSize: 12 }} />
          <YAxis tickFormatter={formatYAxis} tick={{ fontSize: 12 }} />
          <Tooltip content={customTooltip} />
          <Bar dataKey="investment" fill="#14223D" radius={[0, 0, 0, 0]} stackId="a" />
          <Bar dataKey="rentalIncome" fill="#ffd000" radius={[0, 0, 0, 0]} stackId="a" />
          <Bar dataKey="valueAppreciation" fill="#22ff7a" radius={[8, 8, 0, 0]} stackId="a" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

import ScrollAnimationComponent from "../../../components/animation/scroll-animation.component";
import { t } from "../../../utils/cms";
export function HomeGrowthSection() {
  return (
    <ScrollAnimationComponent className="growth" animationName="fadeInDown" animationDelay="2s">
      <>
        <div>
          <h3>{t('homeGrowth.market growth.heading_unprecedented_growth')}</h3>
          <p>{t('homeGrowth.market growth.description_market_growth')}</p>
        </div>
        <div>
          <h3>{t('homeGrowth.expert management.heading_experts_handle_it_all')}</h3>
          <p>{t('homeGrowth.expert management.description_expert_management')}</p>
        </div>
        <div>
          <h3>{t('homeGrowth.security.heading_safety_and_security')}</h3>
          <p>{t('homeGrowth.security.description_safety_and_security')}</p>
        </div>
      </>
    </ScrollAnimationComponent>

  )
}
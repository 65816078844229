import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Content } from "../../components/content/content";
import { Footer } from "../../components/footer/footer";
import { Nav } from "../../components/nav/nav";

import { Bkg } from "../../components/content/bkg";
import { HomeCalculatorSection } from "./section/calculator";
import { HomeComplianceSection } from "./section/compliance";
import { HomeDownloadAppSection } from "./section/download-app";
import { HomeGetStartedSection } from "./section/get-started";
import { HomeGrowthSection } from "./section/growth";
import { HomeHeroSection } from "./section/hero";
import { HomeInvestmentProcessSection } from "./section/investment-process";
import { HomeJoinSection } from "./section/join";
import { HomeMarketplaceSampleSection } from "./section/marketplace-sample";
import { HomeComingSoon } from "./section/coming-soon";
import { HomeDisclaimer } from "./section/disclaimer";

export function HomePage() {

  const [showComingSoon, setShowComingSoon] = useState(false);

  useEffect(() => {
    const agreed = localStorage.getItem('disclaimer-agreed');
    if (agreed) {
      setTimeout(() => {
        setShowComingSoon(true);
      }, 1000);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>PropNerd | Home</title>
        <meta name="description" content="PropNerd harnesses the power of blockchain technology to break down the barriers of traditional real estate investing." />
      </Helmet>

      <Nav />

      <Bkg />
      
      <Content className="home">

        <HomeHeroSection />
        <HomeDisclaimer onHide={() => setShowComingSoon(true) } />
        <HomeComingSoon show={showComingSoon} setShow={setShowComingSoon} />
        <HomeGrowthSection />
        <HomeComplianceSection />
        <HomeInvestmentProcessSection />
        <HomeMarketplaceSampleSection />
        <HomeCalculatorSection />
        <HomeJoinSection />
        <HomeDownloadAppSection />
        <HomeGetStartedSection />

      </Content>

      <Footer />
    </>
  );
}

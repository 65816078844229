import { Content } from "../../components/content/content";
import { Footer } from "../../components/footer/footer";
import { Nav } from "../../components/nav/nav";

import { Helmet } from "react-helmet";
import { Bkg } from "../../components/content/bkg";
import { Link } from "react-router-dom";
import { AppRoute } from "../../routes";
import {t} from '../../utils/cms'

export function Error404Page() {
  return (
    <>
      <Helmet>
        <title>{t('error404.Helmet Information.helmet_title_error_404')}</title>
        <meta name={t('error404.Helmet Information.helmet_meta_name_error_404')} content={t('error404.Helmet Information.helmet_meta_content_error_404')} />
      </Helmet>
      
      <Nav />
    
      <Bkg type='bottom' />
    
      <Content className="static error-content">
        <h2>{t('error404.Content Information.content_heading_error_404')}</h2>
        <p>{t('error404.Content Information.content_description_1_error_404')}</p>
        <p>{t('error404.Content Information.content_description_2_error_404')}</p>
        <p><Link to={'/'}>{t('error404.Content Information.content_link_text_error_404')}</Link></p>
      </Content>
    
      <Footer />
    </>
  
  );
}
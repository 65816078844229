import { PropsWithChildren } from "react";
import classNames from 'classnames';
import { ScrollRestoration } from "react-router-dom";

interface Props extends PropsWithChildren {
  className?: string;
}

export function Content(props: Props) {
  return (
    <div className={classNames('content', props.className)}>
      <ScrollRestoration />
      {props.children}
    </div>
  )
}
import ga4 from 'react-ga4'

const GA_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID as string;
const isProduction = process.env.NODE_ENV === 'production'; 

class _GAService {
  init() {
    ga4.initialize(GA_ID, { 
      testMode: !isProduction 
    })
  }

  sendEvent(name: string) {
    ga4.event('screen_view', {
      app_name: "myApp",
      screen_name: name,
    })
  }

  sendPageview(path: string) {
    ga4.send({ 
      hitType: 'pageview', 
      page: path 
    })
  }
}

export const GAService = new _GAService();

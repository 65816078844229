import { useState } from "react";
import ScrollAnimationComponent from "../../../components/animation/scroll-animation.component";
import { ReactComponent as ImgIconEasy } from "../../../assets/img/home/icon/easy.svg";
import { ReactComponent as ImgIconFast } from "../../../assets/img/home/icon/fast.svg";
import { ReactComponent as ImgIconSecure } from "../../../assets/img/home/icon/secure.svg";
import { t } from "../../../utils/cms";
const ImgFast = require("../../../assets/img/home/fast.webp");
const ImgEasy = require("../../../assets/img/home/easy.webp");
const ImgSecure = require("../../../assets/img/home/secure.webp");

export function HomeInvestmentProcessSection() {
  const [selectedOwnershipSection, setSelectedOwnershipSection] = useState(1);

  return (
    <div className="investment-process">
      <ScrollAnimationComponent animationName="backInDown">
        <h2>
          {t("homeInvestment.Investment Overview.investment_heading_home")}
        </h2>
      </ScrollAnimationComponent>
      <div className="section-content">
        <div className="tabs">
          <div
            className={`tab ${
              selectedOwnershipSection === 1 ? "selected" : ""
            }`}
            onClick={() => setSelectedOwnershipSection(1)}
          >
            {t("homeInvestment.Investment Tabs.investment_tab_fast_home")}
          </div>
          <div
            className={`tab ${
              selectedOwnershipSection === 2 ? "selected" : ""
            }`}
            onClick={() => setSelectedOwnershipSection(2)}
          >
            {t("homeInvestment.Investment Tabs.investment_tab_easy_home")}
          </div>
          <div
            className={`tab ${
              selectedOwnershipSection === 3 ? "selected" : ""
            }`}
            onClick={() => setSelectedOwnershipSection(3)}
          >
            {t("homeInvestment.Investment Tabs.investment_tab_secure_home")}
          </div>
        </div>
        <div className="investment-process-bkg" />
        {selectedOwnershipSection === 1 && (
          <ScrollAnimationComponent
            className="how-section"
            animationName="fadeInLeftBig"
            animationDuration="0.5s"
            animationDelay="1s"
          >
            <div className="how-section-content">
              <div className="how-section-inner-content">
                <div className="icon-wrapper">
                  <ImgIconFast width={120} height={120} />
                </div>
                <div className="how-section-text">
                  <h4>
                    {t(
                      "homeInvestment.Investment Fast Section.fast_section_heading_home"
                    )}
                  </h4>
                  <p className="yellow">
                    {t(
                      "homeInvestment.Investment Fast Section.fast_section_highlight_home"
                    )}
                  </p>
                  <p>
                    {t(
                      "homeInvestment.Investment Fast Section.fast_section_description_home"
                    )}
                  </p>
                </div>
              </div>
              <img
                src={ImgFast}
                className="display-pic bottomless"
                alt={t(
                  "homeInvestment.Investment Overview.investment_heading_home"
                )}
              />
            </div>
          </ScrollAnimationComponent>
        )}
        {selectedOwnershipSection === 2 && (
          <ScrollAnimationComponent
            className="how-section"
            animationName="fadeInLeftBig"
            animationDuration="0.5s"
          >
            <div className="how-section-content">
              <div className="how-section-inner-content">
                <div className="icon-wrapper">
                  <ImgIconEasy width={120} height={120} />
                </div>
                <div className="how-section-text">
                  <h4>
                    {t(
                      "homeInvestment.Investment Easy Section.easy_section_heading_home"
                    )}
                  </h4>
                  <p className="yellow">
                    {t(
                      "homeInvestment.Investment Easy Section.easy_section_highlight_home"
                    )}
                  </p>
                  <p>
                    {t(
                      "homeInvestment.Investment Easy Section.easy_section_description_home"
                    )}
                  </p>
                </div>
              </div>
              <img
                src={ImgEasy}
                className="display-pic bottomless"
                alt={t(
                  "homeInvestment.Investment Overview.investment_heading_home"
                )}
              />
            </div>
          </ScrollAnimationComponent>
        )}
        {selectedOwnershipSection === 3 && (
          <ScrollAnimationComponent
            className="how-section"
            animationName="fadeInLeftBig"
            animationDuration="0.5s"
          >
            <div className="how-section-content">
              <div className="how-section-inner-content">
                <div className="icon-wrapper">
                  <ImgIconSecure width={120} height={120} />
                </div>
                <div className="how-section-text">
                  <h4>
                    {t(
                      "homeInvestment.Investment Secure Section.secure_section_heading_home"
                    )}
                  </h4>
                  <p className="yellow">
                    {t(
                      "homeInvestment.Investment Secure Section.secure_section_highlight_home"
                    )}
                  </p>
                  <p>
                    {t(
                      "homeInvestment.Investment Secure Section.secure_section_description_home"
                    )}
                  </p>
                </div>
              </div>
              <img
                src={ImgSecure}
                width={340}
                className="display-pic bottomless"
                alt={t(
                  "homeInvestment.Investment Overview.investment_heading_home"
                )}
              />
            </div>
          </ScrollAnimationComponent>
        )}
      </div>
    </div>
  );
}

import ScrollAnimationComponent from "../../../components/animation/scroll-animation.component";

const ImgComplianceAicpa = require('../../../assets/img/home/compliance/aicpa.png');
const ImgComplianceCcpa = require('../../../assets/img/home/compliance/ccpa.png');
const ImgComplianceEidvt = require('../../../assets/img/home/compliance/eidvt.png');
const ImgComplianceGdpr = require('../../../assets/img/home/compliance/gdpr.png');
const ImgComplianceIso27001 = require('../../../assets/img/home/compliance/iso27001.png');
const ImgComplianceIso30107 = require('../../../assets/img/home/compliance/iso30107.png');
const ImgCompliancePciDss = require('../../../assets/img/home/compliance/pcidss.png');
const ImgComplianceUkas = require('../../../assets/img/home/compliance/ukas.png');

export function HomeComplianceSection() {
  return (
    <div className="compliance">
      <ScrollAnimationComponent animationName="fadeInDown" className="logos flex space-around flex-wrap gap-lg">
        <img src={ImgComplianceEidvt} alt="EIDVT" height={32} />
        <img src={ImgComplianceGdpr} alt="GDPR" height={32} />
        <img src={ImgCompliancePciDss} alt="PCI DSS" height={32} />
        <img src={ImgComplianceIso27001} alt="ISO 27001" height={32} />
        <img src={ImgComplianceUkas} alt="UKAS" height={32} />
        <img src={ImgComplianceAicpa} alt="AICPA" height={32} />
        <img src={ImgComplianceIso30107} alt="ISO 30107" height={32} />
        <img src={ImgComplianceCcpa} alt="CCPA" height={32} />
      </ScrollAnimationComponent>
    </div>
  );
}
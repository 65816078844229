import { Content } from "../../components/content/content";
import { Footer } from "../../components/footer/footer";
import { Nav } from "../../components/nav/nav";

import classNames from "classnames";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { t } from "../../utils/cms";
export function FaqPage() {

  const [visibleSection, setVisibleSection] = useState<number>();

  const toggleSection = (index: number) => {
    setVisibleSection(visibleSection === index ? undefined : index);
  };

  return (
    <>
      <Helmet>
        <title>{t('faq.Helmet Information.helmet_title_faq')}</title>
        <meta name={t('faq.Helmet Information.helmet_meta_name_faq')} content={t('faq.Helmet Information.helmet_meta_content_faq')} />
      </Helmet>

      <Nav />

      <Content className="faqs">
        <h2>{t('faq.FAQ Content.content_heading_faq')}</h2>
        
        <div className={classNames('section', visibleSection === 1 ? '' : 'collapsed')} onClick={() => toggleSection(1)}>
          <h4>{t('faq.FAQ Content.question_faq_1')}</h4>
          <p>{t('faq.FAQ Content.answer_faq_1')}</p>
        </div>

        <div className={classNames('section', visibleSection === 2 ? '' : 'collapsed')} onClick={() => toggleSection(2)}>
          <h4>{t('faq.FAQ Content.question_faq_2')}</h4>
          <p>{t('faq.FAQ Content.answer_faq_2')}</p>
        </div>

        <div className={classNames('section', visibleSection === 3 ? '' : 'collapsed')} onClick={() => toggleSection(3)}>
          <h4>{t('faq.FAQ Content.question_faq_3')}</h4>
          {/* <p>{t('faq.FAQ Content.answer_faq_3')}</p> */}
          <p>Yes, one of the key benefits of tokenisation is increased liquidity. You can sell your tokens to a third party at any time, subject to market demand. Additionally, once the PropNerd™ secondary market is launched, you will have the option to sell your tokens on our platform, further enhancing your ability to liquidate your investment.</p>
        </div>

        <div className={classNames('section', visibleSection === 4 ? '' : 'collapsed')} onClick={() => toggleSection(4)}>
          <h4>{t('faq.FAQ Content.question_faq_4')}</h4>
          <p>{t('faq.FAQ Content.answer_faq_4')}</p>
        </div>

        <div className={classNames('section', visibleSection === 5 ? '' : 'collapsed')} onClick={() => toggleSection(5)}>
          <h4>{t('faq.FAQ Content.question_faq_5')}</h4>
          {/* <p>{t('faq.FAQ Content.answer_faq_5')}</p> */}
          <p>Yes, one of the major advantages of real estate tokenisation is the ability to invest in properties around the world without geographical restrictions. However, this is subject to the laws and regulations of your specific country and the United Kingdom, where PropNerd™ operates. It is essential to ensure that your investment complies with any legal requirements or restrictions that may apply in your jurisdiction.</p>
        </div>

        <div className={classNames('section', visibleSection === 6 ? '' : 'collapsed')} onClick={() => toggleSection(6)}>
          <h4>{t('faq.FAQ Content.question_faq_6')}</h4>
          <p>{t('faq.FAQ Content.answer_6_faq')}</p>
        </div>

        <div className={classNames('section', visibleSection === 7 ? '' : 'collapsed')} onClick={() => toggleSection(7)}>
          <h4>{t('faq.FAQ Content.question_7_faq')}</h4>
          <p>{t('faq.FAQ Content.answer_7_faq')}</p>
        </div>

      </Content>

      <Footer />
    </>

  );
}
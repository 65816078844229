import { Content } from "../../../components/content/content";
import { Footer } from "../../../components/footer/footer";
import { Nav } from "../../../components/nav/nav";
import { Helmet } from "react-helmet";
import { t } from "../../../utils/cms";

export function QualifiedInvestorPage() {
  return (
    <>
      <Helmet>
        <title>PropNerd | Suitably Qualified Investors</title>
        <meta
          name="description"
          content="Information on the requirements to be considered a suitably qualified investor, including definitions for high net worth individuals and self-certified sophisticated investors."
        />
      </Helmet>

      <Nav />

      <Content className="static ">
        <div className="text-content">
            <h2>{t("QualifiedInvestorPage.Qualified Investors.qualified_investor_page_title")}</h2>
            
            <h4>{t("QualifiedInvestorPage.Qualified Investors.qualified_investor_section_heading")}</h4>
            <h3>{t("QualifiedInvestorPage.Qualified Investors.high_net_worth_individuals_heading")}</h3>
            <p>{t("QualifiedInvestorPage.Qualified Investors.high_net_worth_individuals_description")}</p>
            <h3>{t("QualifiedInvestorPage.Qualified Investors.self_certified_sophisticated_investors_heading")}</h3>
            <p>{t("QualifiedInvestorPage.Qualified Investors.self_certified_sophisticated_investors_description")}</p>
        </div>
      </Content>

      <Footer />
    </>
  );
}

import { Link } from "react-router-dom";
import ScrollAnimationComponent from "../../../components/animation/scroll-animation.component";
import { Carousel } from "../component/carousel.component";
import { AppRoute } from "../../../routes";
import { useSettings } from "../../../context/settings";
import { t } from "../../../utils/cms";

export function HomeMarketplaceSampleSection() {

  const {settings} = useSettings();
  return (
    <div className="marketplace-samples">
  <ScrollAnimationComponent animationName="backInDown">
    <h2>{t('homeMarketplace.Marketplace Overview.marketplace_heading_home')}</h2>
  </ScrollAnimationComponent>
  {settings?.blurProperties && (
    <ScrollAnimationComponent animationName="backInDown">
      <h2>{t('homeMarketplace.Marketplace Overview.marketplace_coming_soon_home')}</h2>
    </ScrollAnimationComponent>
  )}
  <Carousel />
  <Link to={AppRoute.PropertyMarketplace} className="btn btn-wide btn-bold btn-yellow">
    {t('homeMarketplace.Marketplace Overview.marketplace_button_text_home')}
  </Link>
</div>
  )
}
import { useLazyQuery, useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Cms_values from "../../../assets/cms-data.json";
import ImgComingSoon from "../../../assets/img/home/coming_soon.png";
import { MUTATION_ADD_INVESTOR_LEAD } from "../../../graphql/mutations/investor-lead";
import { QUERY_GET_PROPERTY_NAMES } from "../../../graphql/queries/property";
import i18n from "../../../i18n";
import ImgLogo from "./../../../assets/img/common/logo_white.svg";
import ImgQuestionMark from '../../../assets/img/home/icon/question_mark.svg';
import { AppRoute } from "../../../routes";
import { Link } from "react-router-dom";

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
}

export function HomeComingSoon({ show = false, setShow }: Props) {
  const [registerInterest, { data, error }] = useMutation(
    MUTATION_ADD_INVESTOR_LEAD,
    { errorPolicy: "all" }
  );

  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [submitted, setSubmitted] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<string>("");
  const [investmentAmount, setInvestmentAmount] = useState<number | number[]>(
    2000
  );
  const [language, setLanguage] = useState("");
  const [isQualified, setIsQualified] = useState(false);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setInvestmentAmount(newValue as number);
  };

  useEffect(() => {
    const detectedLanguage = i18n.language; // Get the detected language
    setLanguage(detectedLanguage);

    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        setShow(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }
    setErrorMessage(undefined);
    setLoading(false);
    setSubmitted(true);
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [data]);

  useEffect(() => {
    setErrorMessage(error?.message);
    setLoading(false);
  }, [error]);

  function t(key: string) {
    if (!key) {
      return key;
    }
    const [pageKey, sectionKey, valueKey] = key.split(".");

    if (!pageKey || !sectionKey || !valueKey) {
      return key;
    }

    const detectedLanguage = i18n.language.split("-")[0]; // or this.lang
    const valueObj: any = Cms_values.values.find(
      (v: any) =>
        v.page === pageKey &&
        v.section === sectionKey &&
        v.key === valueKey &&
        v.language === detectedLanguage
    );
    return valueObj ? valueObj.value : key;
  }


  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function validateEmail(emailTest: string) {
    return emailRegex.test(emailTest);
  }


  // Extract UTM parameters from URL
  function getUtmParams() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      utm_campaign: urlParams.get("utm_campaign") || undefined,
      utm_medium: urlParams.get("utm_medium") || "Website",
      utm_source: urlParams.get("utm_source") || "Coming Soon",
      utm_content: urlParams.get("utm_content") || undefined,
      utm_term: urlParams.get("utm_term") || undefined,
      utm_id: urlParams.get("utm_id") || undefined,
    };
  }

  function submit(e: FormEvent) {
    e.preventDefault();

    if (!fullName) {
      setErrorMessage("Please enter your full name.");
      return;
    }

    if (!phoneNumber) {
      setErrorMessage("Please enter your phone number.");
      return;
    }

    if (!email) {
      setErrorMessage("Please enter your email address.");
      return;
    }

    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    if (!isValidPhoneNumber(phoneNumber)) {
      setErrorMessage("Please enter a valid phone number.");
      return;
    }

    if (!isQualified) {
      setErrorMessage("Please confirm that you are a suitably qualified investor.");
      return;
    }

    setLoading(true);
    const utmParams = getUtmParams();

    setTimeout(() => {
      registerInterest({
        variables: {
          email: email,
          fullName: fullName,
          phoneNumber: phoneNumber,
          utm_url: "https://propnerd.io",
          preferredPropertyId: selectedProperty,
          expectInvestmentAmount: investmentAmount,
          ...utmParams,
        },
      });
    }, 2000);
  }

  return (
    <>
      <div
        className={`modal-overlay ${show ? "show" : ""}`}
        onClick={() => {
          setShow(false);
        }}
      ></div>
      <div className={`coming-soon ${show ? "show" : ""}`}>
        <div className="logo">
        <img
          src={ImgLogo}
          alt="PropNerd"
          width={160}
          height={40}
        />
        </div>
        <img
          className="coming-soon-img"
          src={ImgComingSoon}
          alt="Coming Soon"
          width={550}
          height={300}
        />
        <h2>{t("homeCommingSoon.Coming soon.coming_soon_heading")}</h2>
        <p>
          {t("homeCommingSoon.Coming soon.coming_soon_description")}
          <br></br> {t("homeCommingSoon.Coming soon.coming_soon_description2")}
        </p>
        <button
          className="btn btn-close"
          onClick={() => {
            setShow(false);
          }}
        >
          X
        </button>
        <div className="separation-line"></div>
        <div className="content">
          <h4 className="form-heading">
            {t("homeCommingSoon.Coming soon.register_interest_heading")}
          </h4>
          {!submitted && (
            <form className="register-form" onSubmit={submit}>
              <div className="input-container">
                <input
                  type="text"
                  id="full-name"
                  name="full-name"
                  placeholder="John Doe"
                  onChange={(e) => setFullName(e.currentTarget.value)}
                  className="form-input"
                />
                <label htmlFor="full-name" className="form-label">
                  Full Name
                </label>
              </div>

              <div className="form-group">
                <div className="input-container">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="user@domain.com"
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    className="form-input"
                  />
                  <label htmlFor="email" className="form-label">
                    Email Address
                  </label>
                </div>
                <div className="input-container">
                  <PhoneInput
                    placeholder="7 333 433 335"
                    defaultCountry="GB"
                    value={phoneNumber}
                    onChange={
                      setPhoneNumber as (value: string | undefined) => void
                    }
                    className="phone-input form-input"
                  />
                  <label htmlFor="phone-number" className="form-label">
                    Phone Number
                  </label>
                </div>
              </div>

              <div className="check-group">
                <label>
                  <input type="checkbox" checked={isQualified} onChange={() => setIsQualified(!isQualified)} />
                  <span>I confirm I am a suitably qualified investor 
                    <Link to={AppRoute.QualifiedInvestor} target="_blank">
                      <img src={ImgQuestionMark} alt="Question mark" width={20} />
                    </Link>
                  </span>
                </label>
              </div>

              <button
                type="submit"
                className="btn btn-primary register-button"
                disabled={loading}
              >
                {!loading ? "Register" : "Registering..."}
              </button>
            </form>
          )}

          {submitted && (
            <div className="info-message mt-2">
              {t("homeCommingSoon.Coming soon.form_success_message")}
            </div>
          )}
          {errorMessage && (
            <div className="error-message text mt-2">{errorMessage}</div>
          )}
        </div>
      </div>
    </>
  );
}
